<template>
  <div class="admin-body">
    <div class="header-admin">
      <h1>Listado de pacientes</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Inventore
        autem non minima hic laboriosam dicta?
      </p>
    </div>
    <b-container >
      <b-row>
        <b-col cols="4">
          <input-text
            class="mt-3"
            :minimum="0"
            :txtBorder="'none'"
            :placeholder="'Buscar...'"
            :txtBackground="'transparent'"
            :txtShadow="'-3px 3px 0px 2px #00a098'"
            @text="filter = $event"
          ></input-text>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            class="mt-3"
            hover
            bordered
            responsive
            sticky-header
            :items="listPatient"
            :fields="fields"
            :filter="filter"
          >
            <template #cell(estado)="row">
              <b-button
                @click="info(row.item)"
                variant="success"
                size="sm"
                class="mr-1">
                Editar
              </b-button>              
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
    <b-modal size="xl" ref="patient-modal" hide-footer headerClass='border-bottom-0' >        
      <edit-patient :propPatient="currentPatient"></edit-patient>
    </b-modal>
  </div>
</template>

<script>
import Patient from "@/services/patients.service";
import InputText from "@/components/inputs/InputText.vue";
import EditPatient from "./EditPatient.vue"

export default {
  components: {
    InputText,
    EditPatient
  },
  data() {
    return {
      currentPatient: null,
      fields: [
        {
          key: "created_at",
          label: "Fecha",
          formatter: (value) => {
            if (value) {
              return value.slice(0, 10);
            }
            return value
          },
        },
        {
          key: "pnombre",
          label: "Nombre completo",
        },
        {
          key: "numedoc",
          label: "Número de documento",
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "direccion",
          label: "Dirección",
        },
        { key: "estado", label: "Editar" },
      ],
      filter: "",
      listPatient: [],
    };
  },
  methods: {
    listPatients() {
      Patient.list()
        .then((res) => {
          if (res.success) {
            this.listPatient = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    info(item) {
      this.currentPatient = item
      this.$refs['patient-modal'].show()
    },
  },
  mounted() {
    this.listPatients();
  },
};
</script>

<style>

</style>