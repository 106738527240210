<template>
  <b-container>
    <b-form @submit.stop.prevent="editUser">
      <b-row class="mt-2 justify-content-center">
        <b-col cols="4">
          <input-text
            :required="false"
            :placeholder="propUser.primer_nombre"
            :txtBorder="'none'"
            :txtBackground="'transparent'"
            :txtShadow="'-3px 3px 0px 2px #00a098'"
            @text="primer_nombre = $event"
          ></input-text>
        </b-col>
        <b-col cols="4">
          <input-text
            :required="false"
            :placeholder="propUser.segundo_nombre"
            :txtBorder="'none'"
            :txtBackground="'transparent'"
            :txtShadow="'-3px 3px 0px 2px #00a098'"
            @text="segundo_nombre = $event"
          ></input-text>
        </b-col>
      </b-row>
      <b-row class="mt-2 justify-content-center">
        <b-col cols="4">
          <input-text
            :required="false"
            :placeholder="propUser.primer_apellido"
            :txtBorder="'none'"
            :txtBackground="'transparent'"
            :txtShadow="'-3px 3px 0px 2px #00a098'"
            @text="primer_apellido = $event"
          ></input-text>
        </b-col>
        <b-col cols="4">
          <input-text
            :required="false"
            :placeholder="propUser.segundo_apellido"
            :txtBorder="'none'"
            :txtBackground="'transparent'"
            :txtShadow="'-3px 3px 0px 2px #00a098'"
            @text="segundo_apellido = $event"
          ></input-text>
        </b-col>
      </b-row>
      <b-row class="mt-2 justify-content-center">
        <b-col cols="4">
          <input-select
            :placeholder="documents.filter(doc => doc.idtipo_documento == propUser.idtipodoc)[0].nombre"
            :options="documents"
            @value="idtipodoc = $event"
          ></input-select>
        </b-col>
        <b-col cols="4 justify-content-center">
          <input-text
            :required="false"
            :placeholder="propUser.numedoc"
            :txtBorder="'none'"
            :txtBackground="'transparent'"
            :txtShadow="'-3px 3px 0px 2px #00a098'"
            @text="numedoc = $event"
          ></input-text>
        </b-col>
      </b-row>
      <b-row class="mt-2 justify-content-center">
        <b-col cols="4">
          <input-text
            :required="false"
            :readonly="true"
            :type="'email'"
            :placeholder="propUser.email"
            :txtBorder="'none'"
            :txtBackground="'transparent'"
            :txtShadow="'-3px 3px 0px 2px #00a098'"
            @text="email = $event"
          ></input-text>
        </b-col>
        <b-col cols="4">
          <input-text
            :required="false"
            :placeholder="propUser.direccion"
            :txtBorder="'none'"
            :txtBackground="'transparent'"
            :txtShadow="'-3px 3px 0px 2px #00a098'"
            @text="direccion = $event"
          ></input-text>
        </b-col>
      </b-row>
      <b-row class="mt-2 justify-content-center">
        <b-col cols="4">
          <input-text
            :required="false"
            :minimum="9"
            :placeholder="propUser.celular"
            :txtBorder="'none'"
            :txtBackground="'transparent'"
            :txtShadow="'-3px 3px 0px 2px #00a098'"
            @text="celular = $event"
          ></input-text>
        </b-col>
        <b-col cols="4">
          <input-text
            :required="false"
            :type="'password'"
            :placeholder="'*******'"
            :txtBorder="'none'"
            :txtBackground="'transparent'"
            :txtShadow="'-3px 3px 0px 2px #00a098'"
            @text="password = $event"
          ></input-text>
        </b-col>
      </b-row>
      <b-row class="mt-2 justify-content-center">
        <b-col cols="4">
          <input-select
            :placeholder="role"
            :options="roles"
            @value="idRole = $event"
          ></input-select>
        </b-col>
        <b-col cols="4"> </b-col>
      </b-row>
      <b-row class="mt-5 justify-content-center">
        <b-col cols="4">
          <button-submit
            :btnBackgroundColor="'#00a098'"
            :text="'Editar usuario'"
            :btnWidth="'320px'"
            :btnFontSize="'1.6em'"
          >
          </button-submit>
        </b-col>
      </b-row>
    </b-form>
    <b-row class="pt-3 justify-content-center">
      <b-col cols="6">
        <b-alert
          :show="dismissCountDown"
          dismissible
          :variant="typeAlert"
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          {{ message }} {{ dismissCountDown }}
        </b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Role from "@/services/role.service";
import User from "@/services/user.service";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";

export default {
  props: {
    propUser: {
      type: Object
    }
  },
  data() {
    return {
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      message: "",
      typeAlert: "success",
      changed: false,
      primer_nombre: "",
      segundo_nombre: "",
      primer_apellido: "",
      segundo_apellido: "",
      email: "",
      idRole: "",
      role: "",
      numedoc: "",
      idtipodoc: "",
      celular: "",
      direccion: "",
      documents: [],
      roles: [],
    };
  },
  components: {
    InputText,
    InputSelect,
    ButtonSubmit,
  },
  created() {
    this.documents = JSON.parse(localStorage.resources).tipo_documentos;
    this.getRoles();
  },
  methods: {
    editUser() {
      if(this.primer_nombre){
        this.propUser.primer_nombre = this.primer_nombre
        this.changed = true
      }
      if(this.segundo_nombre){
        this.propUser.segundo_nombre = this.segundo_nombre
        this.changed = true
      }
      if(this.primer_apellido){
        this.propUser.primer_apellido = this.primer_apellido
        this.changed = true
      }
      if(this.segundo_apellido){
        this.propUser.segundo_apellido = this.segundo_apellido
        this.changed = true
      }
      if(this.email){
        this.propUser.email = this.email
        this.changed = true
      }
      if(this.numedoc){
        this.propUser.numedoc = this.numedoc
        this.changed = true
      }
      if(this.celular){
        this.propUser.celular = this.celular
        this.changed = true
      }
      if(this.direccion){
        this.propUser.direccion = this.direccion
        this.changed = true
      }
      if(this.idRole){
        this.propUser.roles[0].id = this.idRole
        this.changed = true
      }
      if(this.idtipodoc){
        this.propUser.idtipodoc = this.idtipodoc
        this.changed = true
      }
      if (this.changed) {
        User.edit(
          this.propUser
        )
        .then((res) => {
          this.message = res.message
          this.showAlert();
        })
        .catch((err) => console.log(err));
      }
      else{
        this.typeAlert = "warning",
        this.message = "No has realizado ningún cambio"
        this.showAlert();
      }
    },
    getRoles() {
      Role.list()
        .then((res) => {
          this.roles = res.data;
          this.role = this.roles.filter(role => role.id == this.propUser.roles[0].id)[0].name;
          // console.log(this.roles)
        })
        .catch((err) => console.log(err));
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>