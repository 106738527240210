<template>
  <section id="form-dt">
    <b-container fluid>
      <b-row class="form-box">
        <b-col cols="xl-6 md-12" class="first-col">
          <h4>
            {{form.copy1}}
          </h4>
          <h5 class="mt-5">{{form.copy2}}</h5>
        </b-col>
        <b-col cols="xl-6 md-12" class="first-col">
          <b-form @submit.stop.prevent="createContact">
            <b-row>
              <b-col
                ><input-text
                  :minimum="2"
                  :txtColor="'#777'"
                  :txtHeight="'45px'"
                  :txtBorder="'none'"
                  :txtFontSize="'1.1em'"
                  :placeholder="form.holder1"
                  :txtBackground="'#00a09815'"
                  @text="nombres = $event"
                ></input-text
              ></b-col>
              <b-col
                ><input-text
                  :minimum="2"
                  :txtColor="'#777'"
                  :txtBorder="'none'"
                  :txtHeight="'45px'"
                  :txtFontSize="'1.1em'"
                  :placeholder="form.holder2"
                  :txtBackground="'#00a09815'"
                  @text="apellidos = $event"
                ></input-text
              ></b-col>
            </b-row>
            <b-row>
              <b-col
                ><input-text
                  :minimum="6"
                  :type="'email'"
                  :txtColor="'#777'"
                  :txtHeight="'45px'"
                  :txtBorder="'none'"
                  :txtFontSize="'1.1em'"
                  :txtBackground="'#00a09815'"
                  :placeholder="form.holder3"
                  @text="email = $event"
                ></input-text
              ></b-col>
              <b-col
                ><input-text
                  :minimum="9"
                  :type="'number'"
                  :txtColor="'#777'"
                  :txtBorder="'none'"
                  :txtHeight="'45px'"
                  :txtFontSize="'1.1em'"
                  :placeholder="form.holder4"                      
                  :txtBackground="'#00a09815'"
                  :required="true"
                  @text="celular = $event"
                ></input-text
              ></b-col>
            </b-row>
            <b-row>
              <b-col
                ><input-text
                  :minimum="3"
                  :txtColor="'#777'"
                  :txtHeight="'45px'"
                  :txtBorder="'none'"
                  :txtFontSize="'1.1em'"
                  :txtBackground="'#00a09815'"
                  :placeholder="form.holder5"
                  @text="interes = $event"
                ></input-text
              ></b-col>
            </b-row>
            <b-row class="p-2">
              <b-col>
                <button-submit
                  :btnBackgroundColor="'#00a098'"
                  :text="form.btn"
                  :btnWidth="'300px'"
                  :btnFontSize="'1.6em'"
                ></button-submit>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-alert
                  :show="dismissCountDown"
                  dismissible
                  :variant="typeAlert"
                  @dismissed="dismissCountDown = 0"
                  @dismiss-count-down="countDownChanged"
                >
                  {{message}} {{ dismissCountDown }}
                </b-alert>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>        
    </b-container>
  </section>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import ContactForm from "@/services/contactform.service";
import Validate from '@/services/fields.validate.service'
import ButtonSubmit from "@/components/buttons/ButtonSubmit";

export default {
  props: {
    form: {
      type: Object,
      default: null,
    },
  },
  components: {
    InputText,
    ButtonSubmit,
  },
  data() {
    return {
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      message: '',
      typeAlert: 'success',
      nombres:'',
      apellidos:'',
      email:'',
      celular:'', 
      interes:''
    };
  },
  methods: {
    createContact() {   
      if(Validate.mail(this.email) && Validate.phone(this.celular)){
        ContactForm
      }else{
        this.typeAlert = 'warning'
        this.message = "E-mail o Celular invalido"
        this.showAlert();
      }
      ContactForm.create(
        this.nombres,
        this.apellidos,
        this.email,
        this.celular,
        this.interes
      )
        .then((res) => {
            if(res.success){
            this.nombres = ''
            this.apellidos = ''
            this.email = ''
            this.celular = ''
            this.interes = ''
          }else{
            this.typeAlert = 'warning'
          }
          this.message = res.message
          this.showAlert();
          // console.log(res);
        })
        .catch((err) => console.log(err));
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },    
  },
};
</script>

<style>
#form-dt {
  height: 50vh;
  padding: 6em;
}
#form-dt .form-box {
  box-shadow: 0px 0px 8px 6px #ddd;
  border-radius: 50px;
}
#form-dt h1,
#form-dt h4 {
  font-weight: bolder;
}
#form-dt .first-col {
  padding-top: 6em;
  padding-bottom: 6em;
  padding-left: 8em;
  padding-right: 8em;
}

@media screen and (max-width: 1400px) and (orientation: landscape) { 
  #form-dt {
    height: 70vh;
  }
  #form-dt .first-col {
    padding-top: 4em;
    padding-bottom: 4em;
    padding-left: 3em;
    padding-right: 3em;
  }
}
@media screen and (max-width: 600px) and (orientation: portrait) { 
  #form-dt .first-col {
    padding-top: 2em;
    padding-bottom: 1em;
    padding-left: 1em;
    padding-right: 1em;
  }
  #form-dt {
    height: auto;
    padding: 1em;
  }
}

@media screen and (max-width: 360px) and (orientation: portrait) { 
  .button-submit button{
    width: 280px;
  }
  #form-dt h4 {
    font-size: 1.2rem;
  }
}
</style>