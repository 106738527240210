<template>
  <b-container>
    <b-row class="justify-content-center row-info text-center">      
      <b-col cols="4">
        <p>Nombre paciente</p>
      </b-col>
      <b-col cols="4"
        ><p>Documento</p>
      </b-col>
      <b-col cols="4">
        <p>Número celular</p>
      </b-col>
    </b-row>
    <b-row class="justify-content-center row-info text-center">      
      <b-col cols="12">
        <table>
          <tr>
            <td>{{patient.pnombre +' '+ patient.papellido}}</td>
            <td>{{patient.numedoc}}</td>
            <td>{{patient.celular}}</td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <b-row class="justify-content-center row-info text-center">      
      <b-col cols="4">
        <p>Correo</p>
      </b-col>
      <b-col cols="4">
        <p>Procedimiento</p>
      </b-col>
      <b-col cols="4">
        <p>Fecha procedimiento</p>
      </b-col>
    </b-row>
    <b-row class="justify-content-center row-info text-center">      
      <b-col cols="12">
        <table>
          <tr>
            <td>{{patient.email}}</td>
            <td>{{procedure}}</td>
            <td>{{currentDiary.fechaproc}}</td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <b-row class="justify-content-center row-info text-center">      
      <b-col cols="4">
        <p>Hora procedimiento</p>
      </b-col>
      <b-col cols="4">
        <p>Lugar procedimiento</p>
      </b-col>
      <b-col cols="4">
        <p>Resultado procedimiento</p>
      </b-col>
    </b-row>
    <b-row class="justify-content-center row-info text-center">      
      <b-col cols="12">
        <table>
          <tr>
            <td>{{currentDiary.horaproc}}</td>
            <td>{{currentDiary.lugarproc}}</td>
            <td>{{currentDiary.resproc}}</td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <b-row class="justify-content-center row-info text-center">      
      <b-col cols="4">
        <p>Lugar alojamiento</p>
      </b-col>
      <b-col cols="4">
        <p>Observaciones</p>
      </b-col>
      <b-col cols="4">
        <p>Aerolinea con la que voló</p>
      </b-col>
    </b-row>
    <b-row class="justify-content-center row-info text-center">      
      <b-col cols="12">
        <table>
          <tr>
            <td>{{currentDiary.lugaraloja}}</td>
            <td>{{currentDiary.obsproc}}</td>
            <td>{{currentDiary.aerolinea}}</td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <b-row class="justify-content-center row-info text-center">      
      <b-col cols="4">
        <p>Destinos visitado</p>
      </b-col>
      <b-col cols="4"
        ><p>Acompañante de viaje</p>
      </b-col>
      <b-col cols="4">
        <p>Número de celular acompañante</p> 
      </b-col>
    </b-row>
    <b-row class="justify-content-center row-info text-center">      
      <b-col cols="12">
        <table>
          <tr>
            <td>{{currentDiary.destinos}}</td>
            <td>{{currentDiary.nomacompa}}</td>
            <td>{{currentDiary.numacompa}}</td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default { 
  props: {
    //patient data
    patient: {},
    currentDiary: {},
    procedure:{},
  },
};
</script>

<style>
.row-info td,.row-info th {
  border: 1px solid #ccc;
  color: #00a098;
  font-weight: 700;
  width: 33%;
}
.row-info table{
  width: 100%;
}
.row-info p{
  font-weight: 400 !important;
  margin-bottom: 0;
  margin-top: 10px;
}
.title-anotations h5{
  color: #00a098;
  font-weight: 800;
}
</style>