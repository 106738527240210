<template>
  <b-container>
    <b-form @submit.stop.prevent="editPatient">
      <b-row class="mt-1 justify-content-center">
        <b-col cols="4">
          <input-text
            :required="false"
            :placeholder="propPatient.pnombre"
            :txtBorder="'none'"
            :txtBackground="'transparent'"
            :txtShadow="'-3px 3px 0px 2px #00a098'"
            @text="pnombre = $event"
          ></input-text>
        </b-col>
        <b-col cols="4">
          <input-text
            :required="false"
            :placeholder="propPatient.snombre"
            :txtBorder="'none'"
            :txtBackground="'transparent'"
            :txtShadow="'-3px 3px 0px 2px #00a098'"
            @text="snombre = $event"
          ></input-text>
        </b-col>
      </b-row>
      <b-row class="mt-1 justify-content-center">
        <b-col cols="4">
          <input-text
            :required="false"
            :placeholder="propPatient.papellido"
            :txtBorder="'none'"
            :txtBackground="'transparent'"
            :txtShadow="'-3px 3px 0px 2px #00a098'"
            @text="papellido = $event"
          ></input-text>
        </b-col>
        <b-col cols="4">
          <input-text
            :required="false"
            :placeholder="propPatient.sapellido"
            :txtBorder="'none'"
            :txtBackground="'transparent'"
            :txtShadow="'-3px 3px 0px 2px #00a098'"
            @text="sapellido = $event"
          ></input-text>
        </b-col>
      </b-row>
      <b-row class="mt-1 justify-content-center">
        <b-col cols="4">
          <input-select
            :placeholder="documents.filter(doc => doc.idtipo_documento == propPatient.idtipodoc)[0].nombre"
            :options="documents"
            @value="idtipodoc = $event"
          ></input-select>
        </b-col>
        <b-col cols="4 justify-content-center">
          <input-text
            :required="false"
            :placeholder="propPatient.numedoc"
            :txtBorder="'none'"
            :txtBackground="'transparent'"
            :txtShadow="'-3px 3px 0px 2px #00a098'"
            @text="numedoc = $event"
          ></input-text>
        </b-col>
      </b-row>
      <b-row class="mt-1 justify-content-center">
        <b-col cols="4">
          <input-text
            :required="false"
            :readonly="true"
            :type="'email'"
            :placeholder="propPatient.email"
            :txtBorder="'none'"
            :txtBackground="'transparent'"
            :txtShadow="'-3px 3px 0px 2px #00a098'"
            @text="email = $event"
          ></input-text>
        </b-col>
        <b-col cols="4">
          <input-text
            :required="false"
            :minimum="4"
            :placeholder="propPatient.direccion"
            :txtBorder="'none'"
            :txtBackground="'transparent'"
            :txtShadow="'-3px 3px 0px 2px #00a098'"
            @text="direccion = $event"
          ></input-text>
        </b-col>
      </b-row>
      <b-row class="mt-1 justify-content-center">
        <b-col cols="4">
          <input-text
            :required="false"
            :minimum="9"
            :placeholder="propPatient.celular"
            :txtBorder="'none'"
            :txtBackground="'transparent'"
            :txtShadow="'-3px 3px 0px 2px #00a098'"
            @text="celular = $event"
          ></input-text>
        </b-col>
        <b-col cols="4">
          <input-select
            :placeholder="genders.filter(gender => gender.idgenero == propPatient.idgenero)[0].nombre"
            :options="genders"
            @value="idgenero = $event"
          ></input-select>
        </b-col>
      </b-row>
      <b-row class="mt-1 justify-content-center">
        <b-col cols="4">
          <input-calendar-selector
            :placeholder="propPatient.fechanac"
            :txtBorder="'none'"
            :txtBackground="'transparent'"
            :txtShadow="'-3px 3px 0px 2px #00a098'"
            @value="fechanac = $event"
          ></input-calendar-selector>
        </b-col>
        <b-col cols="4">
          <input-type-blood
            :placeholder="bloods.filter(blood => blood.idgrupo_sanguineo == propPatient.idgruposan)[0].nombre_grupo_sanguineo"
            :options="bloods"
            @value="idgruposan = $event"
          ></input-type-blood>
        </b-col>
      </b-row>
      <b-row class="mt-1 justify-content-center">
        <b-col cols="4">
          <input-rh
            :placeholder="rhs.filter(rh => rh.idrh == propPatient.idrh)[0].nombre_rh"
            :options="rhs"
            @value="idrh = $event"
          ></input-rh>
        </b-col>
        <b-col cols="4">
          <input-country 
            :placeholder="location.departamento[0].pais[0].nombre_pais"
            :options="countries"
            @value="idpais = $event"
            @onCountry="getDepartments"
          ></input-country>
        </b-col>
      </b-row>
      <b-row class="mt-1 justify-content-center">
        <b-col cols="4">
          <input-department 
            :placeholder="location.departamento[0].nombre_departamento"
            :options="departments"
            @value="iddepartamento = $event"
            @onDepartment="getCities"
          ></input-department>
        </b-col>
        <b-col cols="4">
          <input-city 
            :placeholder="location.nombre_ciudad"
            :options="cities"
            @value="idciudad = $event"
          ></input-city>
        </b-col>
      </b-row>
      <b-row class="mt-1 justify-content-center">
        <b-col cols="4">
          <input-text
            :required="false"
            :placeholder="propPatient.nombconeme"
            :txtBorder="'none'"
            :txtBackground="'transparent'"
            :txtShadow="'-3px 3px 0px 2px #00a098'"
            @text="nombconeme = $event"
          ></input-text>
        </b-col>
        <b-col cols="4">
          <input-text
            :required="false"
            :minimum="9"
            :placeholder="propPatient.numeconeme"
            :txtBorder="'none'"
            :txtBackground="'transparent'"
            :txtShadow="'-3px 3px 0px 2px #00a098'"
            @text="numeconeme = $event"
          ></input-text>
        </b-col>
      </b-row>
      <b-row class="mt-3 justify-content-center">
        <b-col cols="4">
          <button-submit
            :btnBackgroundColor="'#00a098'"
            :text="'Editar'"
            :btnWidth="'300px'"
            :btnFontSize="'1.6em'"
          >
          </button-submit>
        </b-col>
      </b-row>
    </b-form>
    <b-row class="pt-5 justify-content-center">
      <b-col cols="6">
        <b-alert
          :show="dismissCountDown"
          dismissible
          :variant="typeAlert"
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          {{ message }} {{ dismissCountDown }}
        </b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from "moment";
import Patient from "@/services/patients.service";
import Location from "@/services/locations.service";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import InputRh from "@/components/inputs/InputRh";
import InputTypeBlood from "@/components/inputs/InputTypeBlood";
import InputCountry from "@/components/inputs/InputCountry";
import InputCity from "@/components/inputs/InputCity";
import InputDepartment from "@/components/inputs/InputDepartment";
import InputCalendarSelector from "@/components/inputs/InputCalendarSelector";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
export default {
  props: {
    propPatient: {
      type: Object,
    },
  },
  data() {
    return {
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      message: "",
      typeAlert: "success",
      location: {"departamento":[{"pais":[{"nombre_pais":""}]}]},
      idtipodoc: "",
      idpais: "",
      iddepartamento: "",
      idciudad: "",
      idgenero: "",
      idgruposan: "",
      idrh: "",
      pnombre: "",
      snombre: "",
      papellido: "",
      sapellido: "",
      numedoc: "",
      email: "",
      direccion: "",
      celular: "",
      fechanac: "",
      nombconeme: "",
      numeconeme: "",
      documents: [],
      genders: [],
      bloods: [],
      rhs: [],
      countries: [],
      departments: [],
      cities: [],
    };
  },
  components: {
    InputText,
    InputSelect,
    InputRh,
    InputTypeBlood,
    InputCountry,
    InputCity,
    InputDepartment,
    InputCalendarSelector,
    ButtonSubmit,
  },
  created() {
    this.documents = JSON.parse(localStorage.resources).tipo_documentos;
    this.genders = JSON.parse(localStorage.resources).generos;
    this.bloods = JSON.parse(localStorage.resources).grupo_sanguineo;
    this.rhs = JSON.parse(localStorage.resources).rh;
    this.countries = JSON.parse(localStorage.resources).paises;    
  },
  mounted(){
    this.getLocation()
  },
  methods: {
    editPatient() {
      if (this.pnombre) {
        this.propPatient.pnombre = this.pnombre;
        this.changed = true;
      }
      if (this.snombre) {
        this.propPatient.snombre = this.snombre;
        this.changed = true;
      }
      if (this.papellido) {
        this.propPatient.papellido = this.papellido;
        this.changed = true;
      }
      if (this.sapellido) {
        this.propPatient.sapellido = this.sapellido;
        this.changed = true;
      }
      if (this.numedoc) {
        this.propPatient.numedoc = this.numedoc;
        this.changed = true;
      }
      if (this.celular) {
        this.propPatient.celular = this.celular;
        this.changed = true;
      }
      if (this.direccion) {
        this.propPatient.direccion = this.direccion;
        this.changed = true;
      }
      if (this.idRole) {
        this.propPatient.idRole = this.idRole;
        this.changed = true;
      }
      if (this.idtipodoc) {
        this.propPatient.idtipodoc = this.idtipodoc;
        this.changed = true;
      }
      if (this.idciudad) {
        this.propPatient.idciudad = this.idciudad;
        this.changed = true;
      }
      if (this.idgenero) {
        this.propPatient.idgenero = this.idgenero;
        this.changed = true;
      }
      if (this.idgruposan) {
        this.propPatient.idgruposan = this.idgruposan;
        this.changed = true;
      }
      if (this.idrh) {
        this.propPatient.idrh = this.idrh;
        this.changed = true;
      }
      if (this.fechanac != "Invalid date" && this.fechanac != "") {
        // console.log(this.fechanac)
        this.propPatient.fechanac = moment(this.fechanac).format("YYYY/MM/DD");
        this.changed = true;
      } else {
        this.propPatient.fechanac = moment(this.propPatient.fechanac).format("YYYY/MM/DD");
      }
      if (this.changed) {
        Patient.edit(this.propPatient)
          .then((res) => {
            this.message = res.message;
            this.showAlert();
          })
          .catch((err) => console.log(err));
      } else {
        (this.typeAlert = "warning"),
          (this.message = "No has realizado ningún cambio");
        this.showAlert();
      }
    },
    getDepartments() {
      Location.listDepartments(this.idpais)
        .then((res) => {
          this.departments = res.data.departamentos;
        })
        .catch((err) => console.log(err));
    },
    getCities() {
      Location.listCities(this.iddepartamento)
        .then((res) => {
          this.cities = res.data.ciudades;
        })
        .catch((err) => console.log(err));
    },
    getLocation(){
      Location.getLocation(this.propPatient.idciudad)
      .then((res)=>{
        this.location = res.data.ciudades[0];
        // this.location = true
      })
      .catch((err)=> console.log(err))
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>

<style>
</style>