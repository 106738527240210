<template>
  <div class="admin-body">
    <div class="header-admin">
      <h1>Crear Usuario</h1>
      <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
    </div>
    <b-container>
      <b-form @submit.stop.prevent="createUser">
        <b-row class="mt-2 justify-content-center">
          <b-col cols="4">
            <input-text
              :placeholder="'Primer nombre'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="pnombre = $event"
            ></input-text>
          </b-col>
          <b-col cols="4">
            <input-text
              :required="false"
              :placeholder="'Segundo nombre'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="snombre = $event"
            ></input-text>
          </b-col>
        </b-row>
        <b-row class="mt-2 justify-content-center">
          <b-col cols="4">
            <input-text
              :placeholder="'Primer apellido'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="papellido = $event"
            ></input-text>
          </b-col>
          <b-col cols="4">
            <input-text
              :required="false"
              :placeholder="'Segundo apellido'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="sapellido = $event"
            ></input-text>
          </b-col>
        </b-row>
        <b-row class="mt-2 justify-content-center">
          <b-col cols="4">
            <input-select
              :placeholder="'Tipo de documento'"
              :options="documents"
              @value="idtipodoc = $event"
            ></input-select>
          </b-col>
          <b-col cols="4 justify-content-center">
            <input-text
              :minimum="6"
              :placeholder="'Número de documento'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="numedoc = $event"
            ></input-text>
          </b-col>
        </b-row>
        <b-row class="mt-2 justify-content-center">
          <b-col cols="4">
            <input-text
              :type="'email'"
              :placeholder="'Email'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="email = $event"
            ></input-text>
          </b-col>
          <b-col cols="4">
            <input-text
              :placeholder="'Dirección'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="direccion = $event"
            ></input-text>
          </b-col>
        </b-row>
        <b-row class="mt-2 justify-content-center">
          <b-col cols="4">
            <input-text
              :minimum="9"
              :placeholder="'Celular'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="celular = $event"
            ></input-text>
          </b-col>
          <b-col cols="4">
            <input-text
              :minimum="5"
              :type="'password'"
              :placeholder="'Contraseña'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="password = $event"
            ></input-text>
          </b-col>
        </b-row>                        
        <b-row class="mt-2 justify-content-center">
          <b-col cols="4">
            <input-select
              :placeholder="'Rol'"
              :options="roles"
              @value="idRole = $event"
            ></input-select>
          </b-col>
          <b-col cols="4">            
          </b-col>
        </b-row>                        
        
        <b-row class="mt-5 justify-content-center">
          <b-col>
            <button-submit
              :btnBackgroundColor="'#00a098'"
              :text="'Crear usuario'"
              :btnWidth="'300px'"
              :btnFontSize="'1.6em'">
            </button-submit>
          </b-col>
        </b-row>
      </b-form>
      <b-row class="pt-5">
        <b-col>
          <b-alert
            :show="dismissCountDown"
            dismissible
            :variant="typeAlert"
            @dismissed="dismissCountDown = 0"
            @dismiss-count-down="countDownChanged"
          >
            {{message}} {{ dismissCountDown }}
          </b-alert>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Role from "@/services/role.service";
import User from "@/services/user.service";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";

export default {
  name: "create-patient",
  data() {
    return {
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      message: '',
      typeAlert: 'success',
      roles: [],
      email: "",
      idRole: "",
      numedoc: "",
      pnombre: "",
      snombre: "",
      idtipodoc: "",
      papellido: "",
      sapellido: "",
      celular: "",
      direccion: "",
      documents: []
    };
  },
  components: {
    InputText,
    InputSelect,
    ButtonSubmit,
  },
  created(){
    this.documents = JSON.parse(localStorage.resources).tipo_documentos
    this.getRoles()
  },
  methods: {
    createUser() {
      User.create(
        this.idtipodoc,
        this.idRole,
        this.pnombre,
        this.snombre,
        this.papellido,
        this.sapellido,
        this.numedoc,
        this.email,
        this.direccion,
        this.celular,
        this.password,
      )
      .then((res) =>{
        this.message = res.message
        this.showAlert();
      })
      .catch((err) => console.log(err));
    },
    getRoles(){
      Role.list()
      .then((res) => {
        this.roles = res.data
      })
      .catch((err) => console.log(err));
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },   
  },
};
</script>