<template>
  <section id="sponsors">
    <b-container fluid class="pt-4 pb-2 mr-0 ml-0">
      <b-row>
        <b-col class="justify-content-center">
          <h2>{{sponsors[0].section_title}}</h2>
        </b-col>
      </b-row>

      <b-row class="justify-content-center mt-2">
        <b-col class="col-9">
          <carousel :autoplayTimeout="5000" :perPageCustom="[[240, 1],[350, 1],[480, 1], [768, 2], [1080, 3], [1480, 4]]" :loop="true" :autoplay="true" :navigationEnabled="true"
            navigationNextLabel='<img src="" >'
            navigationPrevLabel='<img src="" >'
            >
            <slide v-for="i in sponsors" :key="i.id">
              <sponsor-card :sponsorsImage="i.image"></sponsor-card>
            </slide>
          </carousel>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
import SponsorCard from '@/components/cards/SponsorCard.vue';

export default {
  props: {
    sponsors: {
      type: Array,
      default: null,
    },
  },
  components: {
    SponsorCard,
    Carousel,
    Slide
  },
};
</script>

<style>
#sponsors {
  margin-top: 18vh;
  margin-bottom: 18vh;
}
#sponsors h2 {
  color: #00a098;
  font-size: 3.5em;
  font-weight: bold;
}
#sponsors p {
  font-size: 1em;
}
#sponsors .VueCarousel-wrapper{
  padding-top: 1em;
  padding-left: 3.5em;
  padding-bottom: 2em;
}
#sponsors .VueCarousel-navigation-next{
  right: -1.5vw;
}
#sponsors .VueCarousel-navigation-prev{
  left: -1.5vw;
}
#sponsors .VueCarousel-navigation-button::before {
  content: "";
  position: absolute;
  top: 8px;
  height: 50px;
  width: 40px;
}

#sponsors .VueCarousel-navigation-next::before {
  background: url('/assets/arrow-r-d.png');
  background-repeat: no-repeat;
  background-size: contain;
  right: -20px;
}

#sponsors .VueCarousel-navigation-prev::before {
  background: url('/assets/arrow-l-d.png');
  background-repeat: no-repeat;
  background-size: contain;
  left: -20px;
}

@media screen and (max-width: 1400px) and (orientation: landscape) { 
  #sponsors {
    margin-bottom: 6em;
  }
}
@media screen and (max-width: 600px) and (orientation: portrait) { 
  #sponsors h2 {
    font-size: 1.8em;
  }
  #sponsors .VueCarousel-navigation-next::before {  
    right: -55px;
  }
  #sponsors .VueCarousel-navigation-prev::before {  
    left: -40px;
  }
  #sponsors .VueCarousel-wrapper{
    padding-left: .4em;
  }
}
@media screen and (max-width: 360px) and (orientation: portrait) { 
  .button-submit button{
    width: 280px;
  }
  #sponsors .VueCarousel-wrapper{
    padding-left: 0em;
  }
}

</style>