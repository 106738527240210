<template>
  <b-card
    :img-src=delegate.image
    img-alt="Image"
    img-top
    style="max-width: 15rem"
    tag="article"
    class="m-auto"
  >
    <b-card-title>{{delegate.name}}</b-card-title>
    <b-card-text >
        <p m-0>{{delegate.city}}</p>
        <p m-0>{{delegate.cel}}</p>
        <p m-0>{{delegate.email}}</p>
    </b-card-text>
  </b-card>
</template>

<script>

export default {
    props:{
        country:{
            type: Object,
            default: null
        },
        delegate:{
            type: Object,
            default: null
        },
    },
}
</script>

<style>
.delegate-card img{
    width: 150px;
    margin: auto;
}
.modal-content {
    text-align: center;
}
.country-modal-line{
    background-color: #00A09870;
    height: 4px;
    border: none;
    width: 60%;
}
.country-modal-title{
    color: #00A098;
    font-size: 2.5em;
    font-weight: bold;
} 
.country-modal-description{
    font-size: 1.2em;
}
.country-modal-table{
    font-size: 1.1em;
    margin: auto;
}
.modal-header .close {
    margin: 0;
    font-size: 2em !important;
} 
</style>