import env from '@/environment/env'

const create = async (
    idtipodoc,
    idciudad,
    idgenero,
    idgruposan,
    idrh,
    pnombre,
    snombre,
    papellido,
    sapellido,
    numedoc,
    email,
    direccion,
    celular,
    fechanac,
    nombconeme,
    numeconeme,
    ) => {
    try{
        let body = {
            'idtipodoc':idtipodoc,
            'idciudad':idciudad,
            'idgenero':idgenero,
            'idgruposan':idgruposan,
            'idrh':idrh,
            'pnombre':pnombre,
            'snombre':snombre,
            'papellido':papellido,
            'sapellido':sapellido,
            'numedoc':numedoc,
            'email':email,
            'direccion':direccion,
            'celular':celular,
            'fechanac':fechanac, 
            'nombconeme':nombconeme,
            'numeconeme':numeconeme,
        }
        const res = await fetch(env.prod+'paciente',{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            },
            body: JSON.stringify(body)
        })
        const data = await res.json();
        return data;
    }catch(err){
        console.log(err)
    }
}

const edit = async (patient) => {
    try{
        let body = {
            'idtipodoc':patient.idtipodoc,
            'idciudad':patient.idciudad,
            'idgenero':patient.idgenero,
            'idgruposan':patient.idgruposan,
            'idrh':patient.idrh,
            'pnombre':patient.pnombre,
            'snombre':patient.snombre,
            'papellido':patient.papellido,
            'sapellido':patient.sapellido,
            'numedoc':patient.numedoc,
            'direccion':patient.direccion,
            'celular':patient.celular,
            'password':patient.password,
            'fechanac':patient.fechanac,
            'nombconeme':patient.nombconeme,
            'numeconeme':patient.numeconeme,
        }
        const res = await fetch(env.prod+'paciente/'+patient.idpaciente,{
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            },
            body: JSON.stringify(body)
        })
        const data = await res.json();
        return data;
    }catch(error){
        return error
    }
}

const list = async ()=>{
    try {
        const res = await fetch(env.prod+'paciente',{
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            }
        })
        const data = await res.json()
        return data
    } catch (error) {
        console.log(error)
    }
}

const find = async (idPatient)=>{
    try {
        const res = await fetch(env.prod+'paciente/'+idPatient,{
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            }
        })
        const data = await res.json()
        return data
    } catch (error) {
        console.log(error)
    }
}

export default{
    create, list, edit, find
}