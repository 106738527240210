import env from '@/environment/env'

const find = async ()=>{
    try {
        const res = await fetch(env.prod+'/',{
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            }
        })
        const data = await res.json()
        return data
    } catch (error) {
        console.log(error)
    }
}

const getPatientPayments = async (idDocument, document)=>{
    try {
        const res = await fetch(env.prod+'pago/'+idDocument+'/'+document,{
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            }
        })
        const data = await res.json()
        return data
    } catch (error) {
        console.log(error)
    }
}

const create = async (
    procedure, idPatient, url, value, dueDate
    ) => {
    try{
        let body = {
            'idestadopago':5,
            'procedimientos':procedure,
            'urlpago':url,
            'fechalimite': dueDate,
            'valor':value,
            'idpaciente':idPatient,
        }
        const res = await fetch(env.prod+'pago',{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            },
            body: JSON.stringify(body)
        })
        const data = await res.json();
        return data;
    }catch(error){
        return error
    }
}

const update = async (
    payment, state
    ) => {
    try{
        let body = {
            'idestadopago':state,
            'procedimientos':payment.procedimientos,
            'urlpago':payment.urlpago,
            'fechalimite':payment.fechalimite,
            'valor':payment.valor,
            'idpaciente':payment.idpaciente,
        }
        const res = await fetch(env.prod+'pago/'+payment.idpago,{
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            },
            body: JSON.stringify(body)
        })
        const data = await res.json();
        return data;
    }catch(error){
        return error
    }
}

const getTypesDocument = async ()=>{
    try {
        const res = await fetch(env.prod+'tipodocumento',{
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        })
        const data = await res.json()
        return data
    } catch (error) {
        console.log(error)
    }
}

const getPaymentStates = async ()=>{
    try {
        const res = await fetch(env.prod+'estadospagos',{
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        })
        const data = await res.json()
        return data
    } catch (error) {
        console.log(error)
    }
}

export default{
    find, create, getPatientPayments, update, getTypesDocument, getPaymentStates
}