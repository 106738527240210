<template>
  <div v-if="info">
    <nav-bar :menus="info[lang].menus" @lang="lang = $event"></nav-bar>
    <banner :banners="info[lang].banners"></banner>
    <ips :infoIps="info[lang].ips"></ips>
    <professionals :professionals="info[lang].professionals"></professionals>
    <package :packages="info[lang].packages"></package>
    <delegates :countries="info[lang].countries"></delegates>
    <testimonials :testimonials="info[lang].testimonials"></testimonials>
    <brand></brand>
    <info></info>
    <form-dt :form="info[lang].form"></form-dt>
    <sponsors :sponsors="info[lang].sponsors"></sponsors>
    <footer-dt></footer-dt>
    <div class="wp-button">
      <a
        target="_blank"
        href="https://api.whatsapp.com/send/?phone=573242802435&text&app_absent=0"
      >
        <img src="../assets/wp.png" width="80" height="80" alt="" />
      </a>
    </div>
  </div>
</template>

<script>
import Ips from "@/views/landing/Ips.vue";
import Info from "@/views/landing/Info.vue";
import FormDt from "@/views/landing/Form.vue";
import Brand from "@/views/landing/Brand.vue";
import Banner from "@/views/landing/Banner.vue";
import NavBar from "@/views/landing/NavBar.vue";
import Package from "@/views/landing/Packages.vue";
import FooterDt from "@/views/landing/Footer.vue";
import Sponsors from "@/views/landing/Sponsors.vue";
import Testimonials from "@/views/landing/Testimonials";
import Professionals from "@/views/landing/Professionals.vue";
import Delegates from "./landing/Delegates.vue";

export default {
  data() {
    return {
      info: null,
      lang: null,
    };
  },
  components: {
    Banner,
    Ips,
    NavBar,
    Professionals,
    Package,
    Testimonials,
    Brand,
    FormDt,
    Sponsors,
    FooterDt,
    Info,
    Delegates,
  },
  methods: {
    scrollMeTo(refName) {
      const element = document.getElementById(refName);
      element.scrollIntoView();
    },
    changeLang() {
      console.log(this.lang, this.info);
    },
  },
  computed: {
    getLang() {
      return () => import(`@/util/lang`);
    },
    getData() {
      return () => import(`@/util/langs/locale.js`);
    },
  },
  created() {
    this.getLang().then((lang) =>
      this.getData().then((locale) => {
        this.info = locale.default.data;
        this.lang = lang.getLang();
      })
    );
  },
  mounted() {
    if (this.$route.query.section) this.scrollMeTo(this.$route.query.section);
  },
};
</script>

<style>
.btn-rigth,
.btn-left {
  position: absolute;
  top: calc(40% - 120px);
  font-size: 4em !important;
  transform: scale(1.5, 1.5);
  box-shadow: none;
  text-shadow: none;
  border: none !important;
  outline: none !important;
}

.VueCarousel-slide {
  position: relative;
  color: #333;
  font-family: Arial;
  font-size: 24px;
  text-align: center;
  min-height: 100px;
}
.VueCarousel-navigation-button[data-v-453ad8cd] {
  top: 40% !important;
}
.VueCarousel-pagination[data-v-438fd353] {
  display: none;
}
.VueCarousel-navigation-button[data-v-453ad8cd]:focus {
  outline: none !important;
}

.label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-header {
  padding-bottom: 0 !important;
}
.modal-body {
  padding-top: 0 !important;
}
.VueCarousel-navigation-prev[data-v-453ad8cd],
.VueCarousel-navigation-next[data-v-453ad8cd] {
  transform: none !important;
  background-color: transparent !important;
}

.wp-button {
  position: fixed;
  bottom: 3em;
  right: 3em;
  background-color: #25d366;
  border-radius: 40px;
  z-index: 1000;
}
.wp-button:hover {
  box-shadow: 1px 1px 6px 2px #ccc;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .wp-button {
    bottom: 2em;
    right: 2em;
  }
}
</style>