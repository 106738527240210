<template>
  <section id="brand">
      <img src="../../assets/logo-azul.png" alt="">
  </section>
</template>

<script>
export default {

}
</script>

<style>
#brand{
    height: 40vh;
    background-image: url("../../assets/fondo-dt.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    text-align: left;
    display: grid;
    align-items: center;
}
#brand img{
    margin-left: 10vw;
}
@media screen and (max-width: 600px) and (orientation: portrait) { 
    #brand {
        height: auto;
        padding: 1em 0;
    }
    #brand img{
        margin-left: 1vw;
        width: 80%;
    }
}
</style>