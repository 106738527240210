<template>
  <b-container>
    <b-row class="justify-content-center row-info text-center">      
      <b-col cols="4">
        <p>Nombre paciente</p>
      </b-col>
      <b-col cols="4"
        ><p>Documento</p>
      </b-col>
      <b-col cols="4">
        <p>Aseguradora</p>
      </b-col>
    </b-row>
    <b-row class="justify-content-center row-info text-center">      
      <b-col cols="12">
        <table>
          <tr>
            <td>{{currentDiary.paciente.pnombre +' '+ currentDiary.paciente.papellido}}</td>
            <td>{{currentDiary.paciente.numedoc}}</td>
            <td>{{currentDiary.aseguradora}}</td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <b-row class="justify-content-center row-info text-center">      
      <b-col cols="4">
        <p>Número celular</p>
      </b-col>
      <b-col cols="4"
        ><p>Correo</p>
      </b-col>
      <b-col cols="4">
        <p>Procedimiento</p>
      </b-col>
    </b-row>
    <b-row class="justify-content-center row-info text-center">      
      <b-col cols="12">
        <table>
          <tr>
            <td>{{currentDiary.paciente.celular}}</td>
            <td>{{currentDiary.paciente.email}}</td>
            <td>procedimiento {{currentDiary.idprocedi}}</td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <b-row class="justify-content-center row-info text-center">      
      <b-col cols="4">
        <p>Ips</p>
      </b-col>
      <b-col cols="4"
        ><p>Número poliza</p>
      </b-col>
      <b-col cols="4">
        <p>Vigencia poliza</p>
      </b-col>
    </b-row>
    <b-row class="justify-content-center row-info text-center">      
      <b-col cols="12">
        <table>
          <tr>
            <td>{{ips}}</td>
            <td>{{currentDiary.numpoliza}}</td>
            <td>{{currentDiary.vigencia}}</td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <b-row class="justify-content-center row-info text-center">      
      <b-col cols="4">
        <p>ACI Acompañante</p>
      </b-col>
      <b-col cols="4"
        ><p>ACI Asesor</p>
      </b-col>
      <b-col cols="4">
        <p>Fecha procedimiento</p>
      </b-col>
    </b-row>
    <b-row class="justify-content-center row-info text-center">      
      <b-col cols="12">
        <table>
          <tr>
            <td>{{currentDiary.acompañante.primer_nombre+ ' '+currentDiary.acompañante.primer_apellido}}</td>
            <td>{{currentDiary.asesor.primer_nombre+ ' '+currentDiary.asesor.primer_apellido}}</td>
            <td>{{currentDiary.fechaproc}}</td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <b-row class="justify-content-center row-info text-center">      
      <b-col cols="4">
        <p>Hora procedimiento</p>
      </b-col>
      <b-col cols="4"
        ><p>Pais de procedencia</p>
      </b-col>
      <b-col cols="4">
        <p>Estado y ciudad</p>
      </b-col>
    </b-row>
    <b-row class="justify-content-center row-info text-center">      
      <b-col cols="12">
        <table>
          <tr>
            <td>{{currentDiary.horaproc}}</td>
            <td>{{currentDiary.ciudad[0].departamento[0].pais[0].nombre_pais}}</td>
            <td>{{currentDiary.ciudad[0].nombre_ciudad}} | {{currentDiary.ciudad[0].departamento[0].nombre_departamento}}</td>
          </tr>
        </table>
      </b-col>
    </b-row>
    
    <b-row class="px-3 pt-4 title-anotations" >
      <b-col class="text-center" cols="12">
        <h5>Anotaciones</h5>
      </b-col>
      <b-col cols="12" v-for="anotation in anotations" :key="anotation.idanotacion">
        <input-text
          :readonly="true"
          :placeholder="anotation.descripcion"
          :txtBackground="'transparent'"
          :txtBorder="'1px solid #aaa'"
          @text="anotation = $event"
        ></input-text>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import DiaryService from "@/services/diary.service"
import InputText from "@/components/inputs/InputText";
export default {
  data(){
    return {
      anotations: []
    }
  },
  props: {
    //patient data
    currentDiary: {},
    ips: {}
  },  
  components: {
    InputText,
  },
  mounted(){
    DiaryService.getAnotations(this.currentDiary.idagenda)
    .then(res => {
      this.anotations = res.data.anotaciones
    })
    .catch(err => console.log(err))
  }
};
</script>

<style>
.row-info td,.row-info th {
  border: 1px solid #ccc;
  color: #00a098;
  font-weight: 700;
  width: 33%;
}
.row-info table{
  width: 100%;
}
.row-info p{
  font-weight: 400 !important;
  margin-bottom: 0;
  margin-top: 10px;
}
.title-anotations h5{
  color: #00a098;
  font-weight: 800;
}
</style>