<template>
  <b-button v-b-hover="hoverHandler" v-b-toggle="toggles" class="button-menu mt-1">{{text}} 
    <img v-if="isHover" class="align-self-right" src="../../assets/arrow-down-w.png" width="26" height="13" alt=""> 
    <img v-else class="align-self-right" src="../../assets/arrow-down-d.png" width="26" height="13" alt=""> 
  </b-button>
</template>

<script>
export default {
  props: {
    text: {type: String},
    toggles: {type: String}
  },
  data(){
    return{
      isHover: false,
    }
  },
  methods: {
    hoverHandler(isHovered) {
      if (isHovered) {
        this.isHover = true
      }else{
        this.isHover = false
      }
    }
  }
}
</script>
