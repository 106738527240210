<template>
  <section id="login">
    <div class="top-banner"></div>
    <b-container>
      <b-row class="justify-content-center">
        <b-col cols="5">
          <div class="login-box">
            <img src="../../assets/logo-administrador.png" alt="" />
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Voluptates, nostrum.
            </p>
            <b-form @submit.stop.prevent="login">
              <input-text-icon
                :minimum="6"
                :type="'email'"
                :txtIcon="'envelope'"
                :txtBorder="'none'"
                :txtHeight="'55px'"
                :txtColor="'#00a098'"
                :txtFontSize="'1.2em'"
                :txtBackground="'#fff'"
                :txtBorderRadius="'15px'"
                :placeholder="'Correo Electronico'"
                :txtShadow="' -3px 3px 0px 2px #00a098'"
                @text="email = $event"
                class="mt-3"
              ></input-text-icon>
              <input-text-icon
                :minimum="6"
                :txtIcon="'lock'"
                :type="'password'"
                :txtHeight="'55px'"
                :txtBorder="'none'"
                :txtColor="'#00a098'"
                :txtFontSize="'1.2em'"
                :txtBackground="'#fff'"
                :txtBorderRadius="'15px'"
                :placeholder="'Contraseña'"
                :txtShadow="' -3px 3px 0px 2px #00a098'"
                @text="password = $event"
                class="mt-3"
              ></input-text-icon>
              <button-submit
              class="mt-5"
                :btnBackgroundColor="'#00a098'"
                :text="'Ingresar'"
                :btnWidth="'300px'"
                :btnFontSize="'1.6em'"
              ></button-submit>
            </b-form>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-alert
            :show="dismissCountDown"
            dismissible
            :variant="typeAlert"
            @dismissed="dismissCountDown = 0"
            @dismiss-count-down="countDownChanged">
            Login fallido {{ dismissCountDown }}
          </b-alert>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import auth from "@/services/auth.service";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import InputTextIcon from "@/components/inputs/InputTextIcon.vue";
export default {
  components: { InputTextIcon, ButtonSubmit },
  data() {
    return {
      email: "",
      password: "",
      typeAlert: 'warning',
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
    };
  },
  methods: {
    login() {
      auth
        .login(this.email, this.password)
        .then((res) => {
          if(res.data.access_token){
            console.log(res.data.resource)
            localStorage.setItem("key", res.data.access_token);
            localStorage.resources = JSON.stringify(res.data.resource)
            this.$router.push("/admin");
          }
          else{
            this.showAlert();
          }
        })
        .catch();
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    }, 
  },
};
</script>

<style>
#login {
  height: 80vh;
}
#login .container{
  height: 100%;  
}
#login .container::before, #login .container::after{
  top: 20%;
  content: '';
  min-width: 2vw;
  min-height: 80%;
  position: absolute;
  background-color: #00a098;
}
#login .container::before{
  left: 0px;
}
#login .container::after{
  right: 0px;
}
.top-banner {
  height: 20vh;
  background-color: #00a09818;
}
.login-box {
  height: 55vh;
  margin-top: 5vh;
  padding-top: 1em;
  min-height: 560px;
  padding-left: 3em;
  padding-right: 3em;
  border-radius: 12px;
  padding-bottom: 2em;
  background-color: #00a09818;
}
.login-box img {
  height: 20vh;
  padding: 1em;
}
.login-box p {
  color: #101010;
  font-size: 1em;
  padding-left: 0em;
  padding-right: 0em;
  font-weight: bold;
}
</style>