<template>
  <div class="admin-body">
    <div class="header-admin">
      <h1>Agendar</h1>
      <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
    </div>
    <b-container>
      <b-form @submit.stop.prevent="findDiary">
        <b-row class="mt-4 justify-content-center">
          <b-col cols="4">
            <input-select
              :placeholder="'Tipo de documento'"
              :options="documents"
              @value="idtipodoc = $event"
            ></input-select>
          </b-col>
          <b-col cols="4 justify-content-center">
            <input-text
              :placeholder="'Número de documento'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="numedoc = $event"
            ></input-text>
          </b-col>
        </b-row>
        <b-row class="mt-3 justify-content-center">
          <b-col>
            <button-submit
              :btnBackgroundColor="'#00a098'"
              :text="'Consultar'"
              :btnWidth="'300px'"
              :btnFontSize="'1.6em'"
            ></button-submit>
          </b-col>
        </b-row>
      </b-form>
      
      <b-row class="mt-5 justify-content-center row-info">
        <b-col cols="3"><p>{{patientName}}</p></b-col>
        <b-col cols="0"><p>|</p></b-col>
        <b-col cols="2"><p>{{patientDocument}}</p></b-col>
        <b-col cols="0"><p>|</p></b-col>
        <b-col cols="3"><p>{{patientCel}}</p></b-col>
        <b-col cols="0"><p>|</p></b-col>
        <b-col cols="2"><p>{{patientMail}}</p></b-col>
      </b-row>

      <b-form @submit.stop.prevent="createDiary">
        <b-row class="mt-4 justify-content-center">
          <b-col cols="4">
            <input-select
              :placeholder="'Procedimiento'"
              :options="procedures"
              @value="idProcedure = $event"
            ></input-select>
          </b-col>
          <b-col cols="4">
            <input-select
              :placeholder="'IPS'"
              :options="dataIps"
              @value="idIps = $event"
            ></input-select>
          </b-col>
          <b-col cols="4">
            <input-text
              :placeholder="'Aseguradora'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="insurance = $event"
            ></input-text>
          </b-col>
        </b-row>
        <b-row class="mt-2 justify-content-center">
          <b-col cols="4">
            <input-text
              :placeholder="'Número de poliza'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="policy = $event"
            ></input-text>
          </b-col>
          <b-col cols="4">
            <input-calendar-selector
              :placeholder="'Vigencia'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @value="dueDate = $event"
            ></input-calendar-selector>
          </b-col>
          <b-col cols="4">
            <input-select
              :placeholder="'ACI Acompañante'"
              :options="escorts"
              @value="idEscort = $event"
            ></input-select>
          </b-col>
        </b-row>
        <b-row class="mt-2 justify-content-center">
          <b-col cols="4">
            <input-select
              :placeholder="'ACI Asesor'"
              :options="advisors"
              @value="idAdvisor = $event"
            ></input-select>
          </b-col>
          <b-col cols="4">
            <input-country
                :placeholder="'País de procedencia'"
                :options="countries"
                @value="idPais = $event"
                @onCountry="getDepartments"
              ></input-country>
          </b-col>
          <b-col cols="4">
            <input-department
                :placeholder="'Departamento de procedencia'"
                :options="departments"
                @value="idDepartamento = $event"
                @onDepartment="getCities"
              ></input-department>
          </b-col>
        </b-row>
        <b-row class="mt-2 justify-content-center">
          <b-col cols="4">
            <input-city              
                :placeholder="'Ciudad de procedencia'"
                :options="cities"
                @value="idCity = $event"
              ></input-city>
          </b-col>
          <b-col cols="4">
            <input-calendar-selector
              :placeholder="'Fecha'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @value="dateDiary = $event"
            ></input-calendar-selector>
          </b-col>
          <b-col cols="4">
            <input-time
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @value="diaryTime = $event"
            ></input-time>
          </b-col>
        </b-row>

        <b-row class="mt-3 justify-content-center">
          <b-col>          
            <button-submit
              :btnBackgroundColor="'#00a098'"
              :text="'Agendar'"
              :btnWidth="'300px'"
              :btnFontSize="'1.6em'"
            ></button-submit>
          </b-col>
        </b-row>
      </b-form>
      <b-row class="pt-3 justify-content-center">
        <b-col cols="6">
          <b-alert
            :show="dismissCountDown"
            dismissible
            :variant="typeAlert"
            @dismissed="dismissCountDown = 0"
            @dismiss-count-down="countDownChanged"
          >
            {{ message }} {{ dismissCountDown }}
          </b-alert>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import UserService from "@/services/user.service"
import DiaryService from "@/services/diary.service"
import Location from "@/services/locations.service";
import InputTime from "@/components/inputs/InputTime";
import InputCity from "@/components/inputs/InputCity";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import InputCountry from "@/components/inputs/InputCountry";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import InputDepartment from "@/components/inputs/InputDepartment";
import InputCalendarSelector from "@/components/inputs/InputCalendarSelector";

export default {
  data() {
    return {
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      message: "",
      typeAlert: "success",
      //list id 
      idIps: "",
      idPais: "",
      idCity: "",
      idEscort: "",
      idAdvisor: "",
      idtipodoc: "",
      idProcedure: "",
      idDepartamento: "",
      //form data
      policy: "",
      numedoc: "",
      dueDate: "",
      dateDiary: "",
      diaryTime: "",
      insurance: "",
      numeconeme: "",
      nombconeme: "",
      aseguradora: "",
      //patient data
      patientMail: "Correo",
      patientDocument: "Documento",
      patientCel: "Número celular",
      patientName: "Nombre paciente",
      dataIps: [],
      cities: [],
      escorts: [],
      advisors: [],
      documents: [],
      countries: [],
      procedures: [],
      departments: [],
    };
  },
  components: {
    InputCity,
    InputTime,
    InputText,
    InputSelect,
    InputCountry,
    InputDepartment,
    InputCalendarSelector,
    ButtonSubmit,
  },
  created() {
    this.documents = JSON.parse(localStorage.resources).tipo_documentos;
    this.countries = JSON.parse(localStorage.resources).paises;
    this.procedures = JSON.parse(localStorage.resources).procedimientos;
    this.dataIps = JSON.parse(localStorage.resources).ips;
  },
  mounted(){
    this.getEscorts()
    this.getAdvisors()
  },
  methods: {
    createDiary(){
      this.dateDiary = moment(this.dateDiary).format("YYYY/MM/DD");
      this.dueDate = moment(this.dueDate).format("YYYY/MM/DD");
      DiaryService.create(
        this.idProcedure,
        this.patient.idpaciente,
        this.idIps,
        this.idEscort,
        this.idAdvisor,
        this.idCity,
        this.dateDiary,
        this.diaryTime,
        this.insurance,
        this.policy,
        this.dueDate,
      )
      .then(res => {
        this.message = res.message
        this.showAlert();
      })
      .catch(err => console.log(err) )
    },
    findDiary() {
      DiaryService.find(
        this.idtipodoc, this.numedoc
      )
      .then((res) => {
        this.patient = res.data.paciente[0]
        this.patientName  = this.patient.pnombre
        this.patientMail = this.patient.email
        this.patientCel = this.patient.celular
        this.patientDocument = this.patient.numedoc
      })
      .catch((err) => console.log(err));
    },
    getEscorts(){
      UserService.listByRole('ACI Acompañante')
      .then((res) => {
        this.escorts = res.data
      })
      .catch((err) => console.log(err));    
    },
    getAdvisors(){
      UserService.listByRole('ACI Asesor')
      .then((res) => {
        this.advisors = res.data
      })
      .catch((err) => console.log(err));    
    },    
    getDepartments(){      
      Location.listDepartments(this.idPais)
      .then((res) => {
        this.departments = res.data.departamentos
      })
      .catch((err) => console.log(err));
    },
    getCities(){      
      Location.listCities(this.idDepartamento)
      .then((res) => {
        this.cities = res.data.ciudades
      })
      .catch((err) => console.log(err));
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>
<style>
.row-info p {
  color: #00a098;
  font-size: 1.1em;
  font-weight: bold;
}
</style>