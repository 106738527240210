<template>
  <b-container>
    <b-form @submit.stop.prevent="createAnotation">
      <b-row class="justify-content-center row-info text-center">
        <b-col cols="3"
          ><p>{{ currentDiary.paciente.pnombre +' '+ currentDiary.paciente.papellido}}</p>
        </b-col>
        <b-col cols="0"><p>|</p></b-col>
        <b-col cols="2"
          ><p>{{ currentDiary.paciente.numedoc }}</p>
        </b-col>
        <b-col cols="0"><p>|</p></b-col>
        <b-col cols="2"
          ><p>{{ currentDiary.paciente.celular }}</p>
        </b-col>
        <b-col cols="0"><p>|</p></b-col>
        <b-col cols="3"
          ><p>{{ currentDiary.paciente.email }}</p>
        </b-col>
      </b-row>
      <b-row class="px-3">
        <input-text
          :placeholder="'Anotación...'"
          :txtBackground="'transparent'"
          :txtBorder="'1px solid #aaa'"
          @text="textAnotation = $event"
        ></input-text>
      </b-row>
      <b-row>
        <b-col>
          <input-calendar-selector
            :placeholder="'Fecha'"
            :txtBorder="'1px solid #aaa'"
            :txtBackground="'transparent'"
            :txtShadow="'none'"
            @value="dateAnotation = $event"
          >
          </input-calendar-selector>
        </b-col>
        <b-col>
          <input-time
            :txtShadow="'none'"
            :txtBorder="'1px solid #aaa'"
            :txtBackground="'transparent'"
            @value="timeAnotation = $event"
          >
          </input-time>
        </b-col>
      </b-row>
      <b-row class="mt-3 justify-content-center">
        <b-col cols="5">
          <button-submit
            :btnBackgroundColor="'#00a098'"
            :text="'Guardar Anotación'"
            :btnWidth="'300px'"
            :btnFontSize="'1.2em'"
          ></button-submit>
        </b-col>
      </b-row>
    </b-form>
    <b-row class="pt-3 justify-content-center">
      <b-col cols="6">
        <b-alert
          :show="dismissCountDown"
          dismissible
          :variant="typeAlert"
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          {{ message }} {{ dismissCountDown }}
        </b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from "moment";
import DiaryService from "@/services/diary.service"
import InputTime from "@/components/inputs/InputTime";
import InputText from "@/components/inputs/InputText.vue";
import InputCalendarSelector from "@/components/inputs/InputCalendarSelector";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
export default {
  data(){
    return{
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      message: "",
      typeAlert: "success",
      //info anotation
      textAnotation:'',
      dateAnotation:'',
      timeAnotation:''
    }
  },
  props: {
    //patient data
    currentDiary: {},
  },
  components: {
    InputTime,
    InputText,
    InputCalendarSelector,
    ButtonSubmit,
  },
  methods: {
    createAnotation() {
      this.dateAnotation = moment(this.dateAnotation).format("YYYY/MM/DD");
      DiaryService.createAnotation(
        this.currentDiary.idagenda,
        this.textAnotation,
        this.dateAnotation,
        this.timeAnotation,
      )
      .then(res => {
        this.message = res.message
        this.showAlert();
      })
      .catch(err => console.log(err) )
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>

<style>
</style>