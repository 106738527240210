<template>
  <div class="admin-body">
    <div class="header-admin">
      <h1>Crear Paciente</h1>
      <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
    </div>
    <b-container>
      <b-form @submit.stop.prevent="createPatient">
        <b-row class="mt-2 justify-content-center">
          <b-col cols="4">
            <input-text
              :placeholder="'Primer nombre'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="pnombre = $event"
            ></input-text>
          </b-col>
          <b-col cols="4">
            <input-text
              :placeholder="'Segundo nombre'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="snombre = $event"
            ></input-text>
          </b-col>
        </b-row>
        <b-row class="mt-2 justify-content-center">
          <b-col cols="4">
            <input-text
              :placeholder="'Primer apellido'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="papellido = $event"
            ></input-text>
          </b-col>
          <b-col cols="4">
            <input-text
              :placeholder="'Segundo apellido'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="sapellido = $event"
            ></input-text>
          </b-col>
        </b-row>
        <b-row class="mt-2 justify-content-center">
          <b-col cols="4">
            <input-select
              :placeholder="'Tipo de documento'"
              :options="documents"
              @value="idtipodoc = $event"
            ></input-select>
          </b-col>
          <b-col cols="4 justify-content-center">
            <input-text
              :placeholder="'Número de documento'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="numedoc = $event"
            ></input-text>
          </b-col>
        </b-row>
        <b-row class="mt-2 justify-content-center">
          <b-col cols="4">
            <input-text
              :type="'email'"
              :placeholder="'Email'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="email = $event"
            ></input-text>
          </b-col>
          <b-col cols="4">
            <input-text
              :minimum="4"
              :placeholder="'Dirección de residencia'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="direccion = $event"
            ></input-text>
          </b-col>
        </b-row>
        <b-row class="mt-2 justify-content-center">
          <b-col cols="4">
            <input-text
              :minimum="9"
              :placeholder="'Celular'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="celular = $event"
            ></input-text>
          </b-col>
          <b-col cols="4">
            <input-select
              :placeholder="'Género'"
              :options="genders"
              @value="idgenero = $event"
            ></input-select>
          </b-col>
        </b-row>
        <b-row class="mt-2 justify-content-center">
          <b-col cols="4">
            <input-calendar-selector
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @value="fechanac = $event"
            ></input-calendar-selector>
          </b-col>
          <b-col cols="4">
            <input-type-blood
              :placeholder="'Grupo sánguineo'"
              :options="bloods"
              @value="idgruposan = $event"
            ></input-type-blood>
          </b-col>
        </b-row>
        <b-row class="mt-2 justify-content-center">
          <b-col cols="4">
            <input-rh
              :placeholder="'Rh'"
              :options="rhs"
              @value="idrh = $event"
            ></input-rh>
          </b-col>
          <b-col cols="4">
            <input-country
              :placeholder="'País de nacimiento'"
              :options="countries"
              @value="idpais = $event"
              @onCountry="getDepartments"
            ></input-country>
          </b-col>
        </b-row>
        <b-row class="mt-2 justify-content-center">
          <b-col cols="4">
            <input-department
              :placeholder="'Departamento de nacimiento'"
              :options="departments"
              @value="iddepartamento = $event"
              @onDepartment="getCities"
            ></input-department>
          </b-col>
          <b-col cols="4">
            <input-city
              :placeholder="'Ciudad de nacimiento'"
              :options="cities"
              @value="idciudad = $event"
            ></input-city>
          </b-col>
        </b-row>
        <b-row class="mt-2 justify-content-center">
          <b-col cols="4">
            <input-text
              :placeholder="'Contacto de emergencia'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="nombconeme = $event"
            ></input-text>
          </b-col>
          <b-col cols="4">
            <input-text
              :minimum="9"
              :placeholder="'Número de emergencia'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="numeconeme = $event"
            ></input-text>
          </b-col>
        </b-row>
        <b-row class="mt-3 justify-content-center">
          <b-col>
            <button-submit
              :btnBackgroundColor="'#00a098'"
              :text="'Crear'"
              :btnWidth="'300px'"
              :btnFontSize="'1.6em'"
            >
            </button-submit>
          </b-col>
        </b-row>
      </b-form>
      <b-row class="pt-3 justify-content-center">
        <b-col cols="6">
          <b-alert
            :show="dismissCountDown"
            dismissible
            :variant="typeAlert"
            @dismissed="dismissCountDown = 0"
            @dismiss-count-down="countDownChanged"
          >
            {{ message }} {{ dismissCountDown }}
          </b-alert>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import Patient from "@/services/patients.service";
import Location from "@/services/locations.service";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import InputRh from "@/components/inputs/InputRh";
import InputTypeBlood from "@/components/inputs/InputTypeBlood";
import InputCountry from "@/components/inputs/InputCountry";
import InputCity from "@/components/inputs/InputCity";
import InputDepartment from "@/components/inputs/InputDepartment";
import InputCalendarSelector from "@/components/inputs/InputCalendarSelector";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";

export default {
  data() {
    return {
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      message: "",
      typeAlert: "success",
      idtipodoc: "",
      idpais: "",
      iddepartamento: "",
      idciudad: "",
      idgenero: "",
      idgruposan: "",
      idrh: "",
      pnombre: "",
      snombre: "",
      papellido: "",
      sapellido: "",
      numedoc: "",
      email: "",
      direccion: "",
      celular: "",
      fechanac: "",
      nombconeme: "",
      numeconeme: "",
      documents: [],
      genders: [],
      bloods: [],
      rhs: [],
      countries: [],
      departments: [],
      cities: [],
    };
  },
  components: {
    InputText,
    InputSelect,
    InputRh,
    InputTypeBlood,
    InputCountry,
    InputCity,
    InputDepartment,
    InputCalendarSelector,
    ButtonSubmit,
  },
  created() {
    this.documents = JSON.parse(localStorage.resources).tipo_documentos;
    this.genders = JSON.parse(localStorage.resources).generos;
    this.bloods = JSON.parse(localStorage.resources).grupo_sanguineo;
    this.rhs = JSON.parse(localStorage.resources).rh;
    this.countries = JSON.parse(localStorage.resources).paises;
  },

  methods: {
    createPatient() {
      this.fechanac = moment(this.fechanac).format("YYYY/MM/DD");
      Patient.create(
        this.idtipodoc,
        this.idciudad,
        this.idgenero,
        this.idgruposan,
        this.idrh,
        this.pnombre,
        this.snombre,
        this.papellido,
        this.sapellido,
        this.numedoc,
        this.email,
        this.direccion,
        this.celular,
        this.fechanac,
        this.nombconeme,
        this.numeconeme
      )
        .then((res) => {
          this.message = res.message;
          this.showAlert();
        })
        .catch((err) => console.log(err));
    },
    getDepartments() {
      Location.listDepartments(this.idpais)
        .then((res) => {
          this.departments = res.data.departamentos;
        })
        .catch((err) => console.log(err));
    },
    getCities() {
      Location.listCities(this.iddepartamento)
        .then((res) => {
          this.cities = res.data.ciudades;
        })
        .catch((err) => console.log(err));
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>