<template>
  <div class="w-100 p-2 mx-auto">
    <select v-model="selected" :style="txtStyles" required>
        <option value="null" disabled selected>{{placeholder}}</option>
        <option v-for="option in options" v-bind:value="option.idrh" :key="option.idrh">
          {{ option.nombre_rh }}
        </option>
    </select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: null,
    };
  }, 
  props: {      
    options: [],
    placeholder: {
      type: String,
      default: "Ingrese su respuesta...",
    },
    type: {
      type: String,
      default: "text",
    },
    minimum: {
      type: Number,
      default: 2,
    },
    txtHeight: {
      type: String,
      default: "50px",
    },
    txtBackground: {
      type: String,
      default: "#transparent",
    },
    txtShadow: {
      type: String,
      default: "-3px 3px 0px 2px #00a098",
    },
    txtBorder: {
      type: String,
      default: "none",
    },
    txtBorderRadius: {
      type: String,
      default: "8px",
    },
    txtColor: {
      type: String,
      default: "#495057",
    },
    txtFontSize: {
      type: String,
      default: "1rem",
    },
  },
  updated() {
    this.$emit("value", this.selected);
  },
  computed: {
    txtStyles() {
      return {
        "--txt-color": this.txtColor,
        "--txt-height": this.txtHeight,
        "--txt-border": this.txtBorder,
        "--txt-border-radius": this.txtBorderRadius,
        "--txt-shadow": this.txtShadow,
        "--txt-font-size": this.txtFontSize,
        "--txt-background": this.txtBackground,
      };
    },
  }
};
</script>

<style>
select {
  outline: none;
  width: 100%;
  text-align: center;
  color: var(--txt-color) !important;
  height: var(--txt-height) !important;
  border: var(--txt-border) !important;
  border-radius: var(--txt-border-radius) !important;
  box-shadow: var(--txt-shadow) !important;
  font-size: var(--txt-font-size) !important;
  background-color: var(--txt-background) !important;
}
</style>