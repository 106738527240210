import env from '@/environment/env'

const find = async (typeDocument, document)=>{
    try {
        const res = await fetch(env.prod+'agenda/'+typeDocument+'/'+document,{
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            }
        })
        const data = await res.json()
        return data
    } catch (error) {
        console.log(error)
    }
}

const create = async (
    idProcedure, idPatient, idIps, idEscort, idAdvisor, 
    idCity, dateDiary, diaryTime, insurance, policy, dueDate
    ) => {
    try{
        let body = {
            'idprocedi':idProcedure,
            'idpaciente':idPatient,
            'idips':idIps,
            'idacompa':idEscort,
            'idasesor':idAdvisor,
            'idciudad':idCity,
            'idestagenda':1,
            'fechaproc':dateDiary,
            'horaproc':diaryTime,
            'aseguradora':insurance,
            'numpoliza':policy,
            'vigencia':dueDate,
        }
        const res = await fetch(env.prod+'agenda',{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            },
            body: JSON.stringify(body)
        })
        const data = await res.json();
        return data;
    }catch(error){
        return error
    }
}

const list = async ()=>{
    try {
        const res = await fetch(env.prod+'agenda',{
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            }
        })
        const data = await res.json()
        return data
    } catch (error) {
        return error
    }
}

const updateDiary = async (id, state)=>{
    try {
        let body = {
            'idestagenda':state,
        }
        const res = await fetch(env.prod+'agenda/changeState/'+id,{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            },
            body: JSON.stringify(body)
        })        
        const data = await res.json()
        return data
    } catch (error) {
        return error
    }
}

const createAnotation = async(
    idDiary, description, date, time
) =>{
    try {
        let body = {
            'idagenda':idDiary,
            'descripcion':description,
            'fecha':date,
            'hora':time
        }
        const res = await fetch(env.prod+'anotacion',{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            },
            body: JSON.stringify(body)
        })
        const data = await res.json();
        return data;
    } catch (error) {
        return error
    }
}

const getAnotations = async (id)=>{
    try {
        const res = await fetch(env.prod+'agenda/'+id,{
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            }
        })
        const data = await res.json()
        return data
    } catch (error) {
        return error
    }
}


export default{
    find, create, list, createAnotation, getAnotations, updateDiary
}