<template>
  <div class="w-100 p-2 mx-auto">
    <b-form-timepicker v-model="value" locale="en" class="mt-2" :placeholder="placeholder" :style="txtStyles"></b-form-timepicker>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        value: ''
      }
    },
    props: {
      placeholder:      {
        type: String,
        default: 'Hora' 
      },
      txtShadow: {
        type: String,
        default: "-3px 3px 0px 2px #00a098",
      },
      txtBorder: {
        type: String,
        default: "none",
      },
    },
    updated() {
      this.$emit('value', this.value);
    },
    computed: {
      txtStyles() {
        return {
          "--txt-shadow": this.txtShadow,
          "--txt-border": this.txtBorder,
        }
      }
    }
  }
</script>

<style scope>
.b-form-timepicker{
  outline: none;
  width: 100%;
  text-align: center;
  height: 50px !important;
  margin-top: 0 !important;
  border: var(--txt-border) !important;
  border-radius: 8px;
  box-shadow: var(--txt-shadow) !important;
}
.b-form-timepicker  label{
  padding: 1em;
}
</style>