import env from '@/environment/env'

const create = async (
    idPatient, idProcedure, 
    idResult, dateProcedure, 
    timeProcedure,placeProcedure,
    housingPlace, airline,
    visitedLocations, travelPartner,
    numberPartner, observartions,
    ) => {
    try{
        let body = {
            'idpaciente': idPatient,
            'idprocedi': idProcedure,
            'idresproc': idResult,
            'fechaproc': dateProcedure,
            'horaproc': timeProcedure,
            'lugarproc': placeProcedure,
            'lugaraloja': housingPlace,
            'aerolinea': airline,
            'destinos': visitedLocations,
            'nomacompa': travelPartner,
            'numacompa': numberPartner,
            'obsproc': observartions,
        }
        const res = await fetch(env.prod+'gestionprocedimiento',{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            },
            body: JSON.stringify(body)
        })
        const data = await res.json();
        return data;
    }catch(error){
        return error
    }
}

const changeState = async (idprocedure, state)=>{
    try {
        let body = {
            'idestagesproc': state
        }
        const res = await fetch(env.prod+'gestionprocedimiento/changeState/'+idprocedure,{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            },
            body: JSON.stringify(body)
        })
        const data = await res.json()
        return data
    } catch (error) {
        console.log(error)
    }
}

const list = async (idDocument, document)=>{
    try {
        const res = await fetch(env.prod+'gestionprocedimiento/'+idDocument+'/'+document,{
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            }
        })
        const data = await res.json()
        return data
    } catch (error) {
        console.log(error)
    }
}

export default{
    create, list, changeState
}