const mail = (mail) => {
    return mail.includes("@") && mail.includes(".") && mail.length > 9 ? true : false            
}

const phone = (phone) => {
    return phone.toString().length >= 10 ? true : false
}

export default {
    mail, phone
}