<template>
  <div class="admin-body">
    <div class="header-admin">
      <h1>Lista de agenda</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Inventore
        autem non minima hic laboriosam dicta?
      </p>
    </div>
    <!-- <h1 v-for="form in listPatient" :key="form.idformulario_contacto">forms</h1> -->
    <b-container fluid class="px-5">
      <b-row>
        <b-col cols="4">
          <input-text
            class="mt-3"
            :minimum="0"
            :txtBorder="'none'"
            :placeholder="'Buscar...'"
            :txtBackground="'transparent'"
            :txtShadow="'-3px 3px 0px 2px #00a098'"
            @text="filter = $event"
          ></input-text>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            class="mt-3"
            hover
            bordered
            responsive
            sticky-header
            :items="diaries"
            :fields="fields"
            :filter="filter">
            <template #cell(estado)="row">
              <b-button
                variant="success"
                size="sm"
                @click="info(row.item)"
                class="mr-1">
                Ver
              </b-button>
              <span v-if="states">
              <b-button
                v-if="row.item.idestagenda == states[0].id_estados_agenda"
                variant="success"
                size="sm"
                @click="cancel(row.item.idagenda, states[1].id_estados_agenda )"
                class="mr-1">
                {{states[0].texto}}
              </b-button>
              <b-button
                v-else
                variant="danger"
                size="sm"
                @click="cancel(row.item.idagenda, states[0].id_estados_agenda )"
                class="mr-1">
                {{states[1].texto}}
              </b-button>
              </span>
              <b-button
                variant="success"
                size="sm"
                @click="openAnotation(row.item)"
                class="mr-1">
                Crear Anotación
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row class="pt-3 justify-content-center">
        <b-col cols="6">
          <b-alert
            :show="dismissCountDown"
            dismissible
            :variant="typeAlert"
            @dismissed="dismissCountDown = 0"
            @dismiss-count-down="countDownChanged"
          >
            {{ message }} {{ dismissCountDown }}
          </b-alert>
        </b-col>
      </b-row>
    </b-container>
    <b-modal size="lg" ref="diary-detail-modal" hide-footer headerClass='border-bottom-0' >        
      <detail-anotation :currentDiary="currentDiary" :ips="currentIps"></detail-anotation>
    </b-modal>
    <b-modal size="lg" ref="anotation-create-modal" hide-footer headerClass='border-bottom-0' >        
      <create-anotation :currentDiary="currentDiary"></create-anotation>
    </b-modal>
  </div>
</template>

<script>
import DiaryService from "@/services/diary.service";
import InputText from "@/components/inputs/InputText.vue";
import CreateAnotation from "./CreateAnotation.vue";
import DetailAnotation from "./DetailAnotation.vue";
export default {
  components: {
    InputText,
    CreateAnotation,
    DetailAnotation
  },
  data() {
    return {
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      message: "",
      typeAlert: "success",

      currentDiary: null,
      currentIps: {},

      //table data
      fields: [       
        {key:"paciente",label:"Nombre", 
          formatter: (value)=>{
            return value.pnombre+ ' ' +value.papellido
          }
        },
        {key:"fechaproc",label:"Fecha"},
        {key:"idprocedi",label:"Procedimiento",
          formatter: (value) => {
            if (value) {
              return this.procedures.filter((el)=>{
                return el.idprocedimiento == value
              })[0].nombre_procedimiento
            }
            return value
          },
        },
        {label:"ACI acompañante",key:"acompañante",
          formatter: (value) => {
            if (value) {
              return value.primer_nombre +' '+value.primer_apellido
            }
            return value
          },
        },
        {label:"ACI asesor",key:"asesor",formatter: (value) => {
            if (value) {
              return value.primer_nombre +' '+value.primer_apellido
            }
            return value
          },
        },        
        { key: "estado", label: "Editar" },
      ],
      filter: "",
      diaries: [],
      states: [],
      procedures: [],
      localIps: []
    };
  },
  methods: {
    listDiaries() {
      DiaryService.list()
      .then((res) => {
        if (res.success) {
          this.diaries = res.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    info(item) {
      this.currentDiary = item
      this.currentIps = this.localIps.filter((el)=>{
        return el.idips == this.currentDiary.idips
      })[0].nombre_ips
      this.$refs['diary-detail-modal'].show()
    },
    openAnotation(item) {
      this.currentDiary = item
      this.$refs['anotation-create-modal'].show()
    },
    cancel(id, state) {
      DiaryService.updateDiary(id, state)
      .then((res) => {
        if (res.success) {
          this.listDiaries();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
  created(){
    this.listDiaries();
    this.procedures = JSON.parse(localStorage.resources).procedimientos
    this.states = JSON.parse(localStorage.resources).estados_agenda
    this.localIps = JSON.parse(localStorage.resources).ips;
  },
};
</script>

<style>
</style>