<template>
  <b-card v-b-modal="testimonialDt.id+testimonialDt.title"
    :img-src=testimonialDt.image
    img-alt="Image"
    img-top
    tag="article"
    style="max-width: 20rem"
    class="mb-2"
  >
    <b-card-title>{{testimonialDt.title}}</b-card-title>
    <b-card-text >
        {{testimonialDt.description}}
        <b-modal size="lg" class="package-modal" :id="testimonialDt.id+testimonialDt.title" hide-footer  headerClass='border-bottom-0' >
            <h3 class="package-modal-title">{{testimonialDt.title}}</h3>
            <hr class="testimonial-modal-line">
            <b-embed
                type="iframe"
                aspect="16by9"
                :src="testimonialDt.video"
                allowfullscreen
            ></b-embed>
        </b-modal>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
props:{
        testimonialDt:{
            type: Object,
            default: null
        },
    }
}
</script>

<style>
#testimonials{
    padding-bottom: 10vh;
}
#testimonials article{
    background-color: transparent;
    border: none;
    border-radius: 40px;
    align-items: center;
    min-width: 90%;
    width: auto;
    margin: auto;
    padding: 1em 1em 0em 1em;
}
#testimonials article p{
    text-align: center;
    font-weight: 400;
}
#testimonials article h4{
    font-size: 1.6em;
    color: #00A098 !important;
    font-weight: 700;
}

#testimonials .card-img-top{
    border-radius: 50%;
    width: 200px;
    height: 200px;
}
/* #testimonials .card-body img{
    border-radius: none;
    max-height: 100px;
    max-width: 200px;
} */
.testimonial-modal-line{
    border: none;
    background-color: #000;
    height: 4px;
    margin-bottom: 1em;
    margin-top: 0;
}
#testimonial h5{
    text-align: center;
    margin: auto;
}
@media screen and (max-width: 1400px) and (orientation: landscape) {
    #testimonials article h4{
        font-size: 1.2em;
    }
    #testimonials p {
       font-size: 0.7em;
    }
}
</style>