<template>
  <b-card
    v-b-modal="ips.id + ips.title"
    :title=ips.title
    :img-src=ips.image
    img-alt="Image"
    img-top
    tag="article"
    style="max-width: 20rem"
    class="mb-2"
  >
    <b-card-text>
      {{ips.description}}
    </b-card-text>
    <b-modal size="lg" class="ips-modal" :id="ips.id + ips.title" hide-footer headerClass='border-bottom-0' >
        <h3 class="ips-modal-title">{{ips.title}}</h3>
        <hr class="ips-modal-line">
        <p class="ips-modal-description">{{ips.description}}</p>
        <hr class="ips-modal-line">
        <a class="web-link" target="_blank" :href="ips.web">Clic para más Información</a>
        <!-- <b-container fluid v-if="showProfessionals">
            <b-row class="justify-content-center p-1">
                <b-col class="col-12 ">
                <carousel :autoplayTimeout="1000" :perPageCustom="[[480, 1], [768, 2], [1080, 2], [1080, 3]]" :loop="true" :autoplay="true" :navigationEnabled="true"
                    navigationNextLabel='<img src="" >'
                    navigationPrevLabel='<img src="" >' 
                    >
                    <slide  v-for="i in ips.professionals" :key="i.id">
                        <professional-modal-card :professional="i"></professional-modal-card>
                    </slide>
                </carousel>
                </b-col>
            </b-row>
        </b-container> -->
    </b-modal>
  </b-card>
</template>

<script>
// import ProfessionalModalCard from '@/components/cards/ProfessionalModalCard.vue'
// import { Carousel, Slide } from 'vue-carousel'
export default {
    data(){
        return{
            showProfessionals: false
        }
    },
    props:{
        ips: {
            type: Object,
            default: null
        },
    },
    components: {
        // ProfessionalModalCard,
        // Carousel,
        // Slide
    },
    mounted(){
        this.showProfessionals=true
    }
    
};
</script>

<style>
#ips article{
    box-shadow: #efefef 0px 0px 10px 6px;
    border-radius: 40px;
    align-items: center;
    min-width: 90%;
    width: 90%;
    padding: 1em 1em 1em 1em;
}
#ips article p{
    text-align: justify;
    font-weight: 400;
}
#ips article h4{
    color: #00A098;
    font-weight: 600;
}
#ips .card img{
    border-radius: 50%;
    width: 11vw;
    height: 11vw;
    min-height: 180px;
    min-width: 180px;
    box-shadow: #ccc 0px 0px 8px 2px;
}
.web-link{
    color: #9C182B;
    font-size: 1.2em;
    text-decoration: underline;
}
</style>