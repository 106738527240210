<template>
  <b-card
    :img-src=professional.image
    img-alt="Image"
    img-top
    tag="article"
    style="max-width: 20rem"
    class="mb-2"
  >
    <b-card-title>{{professional.name}}</b-card-title>
    <b-card-sub-title class="mb-3">{{professional.title}}</b-card-sub-title>
    <b-card-text >
        {{professional.description}}
    </b-card-text>
    <b-card-body>
        <img :src="professional.ipsImage1" alt="">
        <img :src="professional.ipsImage2" alt="">
    </b-card-body>
  </b-card>
</template>

<script>
export default {
    props:{
        professional: {
            type: Object,
            default: null
        },
    }
}
</script>

<style>
#professionals article{
    box-shadow: #efefef 0px 0px 10px 6px;
    border-radius: 40px;
    align-items: center;
    min-width: 90%;
    width: 90%;
    padding: 1em 1em 1em 1em;
}
#professionals article p{
    text-align: justify;
    font-weight: 400;
}
#professionals article h4{
    font-size: .9em;
}
#professionals article h6{
    color: #00A098 !important;
    font-weight: 700;
    text-align: center;
    font-size: 1.1em;
}

#professionals .card-img-top{
    border-radius: 50%;
    width: 11vw;
    height: 11vw;
}
#professionals .card-body{
    padding: 0em;
    display: block;
}
#professionals .card-body img{
    border-radius: 0;
    max-height: 60px;
    max-width: 125px;
    margin: 0;
    padding: 5px;
}
@media screen and (max-width: 600px) and (orientation: portrait) { 
    #professionals article p{
        font-size: 0.55em;
    }
}
</style>