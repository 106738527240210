<template>
  <b-card
    :img-src=packageDt.image
    img-alt="Image"
    img-top
    style="max-width: 20rem"
    tag="article"
    class="mb-2"
  >
    <b-card-title>{{packageDt.title}}</b-card-title>
    <b-card-text >
        <!-- <p class="price">{{packageDt.cop}} <span>COP</span> | {{packageDt.usd}} <span>USD</span></p> -->
        <hr class="line">
        <!-- <p class="subtitle">Precios Comparativos</p> -->
        <table>
            <!-- <tr>
                <th class="city">Bogotá:</th>
                <th>3.800.000</th>
                <th>COP</th>
            </tr>
            <tr>
                <th class="city">Europa:</th>
                <th>1.150</th>
                <th>EUR</th>
            </tr> -->
            <!-- <tr>
                <th class="city">USA:</th>
                <th>{{packageDt.usa}}</th>
                <th>USD</th>
            </tr> -->
        </table>
        <button-get-into v-b-modal="packageDt.id"
            :btnHeight="'30px'"
            :btnWidth="'100%'"
            :text="btnText"
            :btnBackgroundColor="'#00A098'"
            :btnFontSize="'.95em'"
            :onTap="()=>{}"
            >
        </button-get-into>
        <b-modal size="lg" class="package-modal" :id="packageDt.id" hide-footer headerClass='border-bottom-0' >
            <h3 class="package-modal-title">{{packageDt.title}}</h3>
            <hr class="package-modal-line">
            <p class="package-modal-description">{{packageDt.description}}</p>
            <hr class="package-modal-line">
            <table class="package-modal-table">
                <tr v-for="i in packageDt.details" :key="i.id">
                    <th>{{i.name}}</th>
                    <!-- <th style="text-align:end; color: #00A098">{{i.price}}</th> -->
                </tr>
            </table>
        </b-modal>
    </b-card-text>
  </b-card>
</template>

<script>
import ButtonGetInto from '../buttons/ButtonGetInto.vue'

export default {
    props:{
        packageDt:{
            type: Object,
            default: null
        },
        btnText:{
            type:String,
            default:""
        }
    },
    components:{
        'button-get-into': ButtonGetInto
    }
}
</script>

<style>

#package article{
    display: block;
    border: none;
    border-radius: 40px;
    width: auto;
    margin: auto;
    padding: 1em 1em 1em 1em;
    background-color: transparent;
    min-width: 80%;
}
#package article p{
    text-align: center;
    font-weight: 400;
}
#package article .card-title{
    font-size: large;
    color: #00A098 !important;
    font-weight: 700;
    margin-bottom: 4px;
}
#package .card-img-top{
    border-radius: 50%;
    width: 13vw;
    height: 13vw;
    box-shadow: #ccc 0px 0px 10px 3px;
}
#package .card-body img{
    border-radius: none;
    max-height: 100px;
    max-width: 300px;
}
#package article .price{
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 0;
}
#package article .price span{
    font-size: 0.7em;
}
#package hr{
    background-color: #00A09840;
    height: 6px;
    margin-bottom: 5px;
    margin-top: 5px;
    border: none;
}
#package article .subtitle{
    margin-bottom: 10px;
    font-size: .8em;
    color: #00A098;
    font-weight: bold;
}
#package article table{ 
    width: 100%;
}
#package article th{
    text-align: end;
    font-size: .8em;
    line-height: 1.1em;
}
#package article .city{
    text-align: start;

}
.modal-content {
    text-align: center;
}
.package-modal-line{
    background-color: #00A09870;
    height: 4px;
    border: none;
    width: 60%;
}
.package-modal-title{
    color: #00A098;
    font-size: 2.5em;
    font-weight: bold;
} 
.package-modal-description{
    font-size: 1.2em;
}
.package-modal-table{
    font-size: 1.1em;
    margin: auto;
}
.modal-header .close {
    margin: 0;
    font-size: 2em !important;
}
</style>