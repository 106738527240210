import env from '@/environment/env'

const create = async (
    nombres, apellidos, email, celular, interes
) => {
    try {
        let body = {
            'nombres':nombres, 
            'apellidos':apellidos, 
            'email':email, 
            'celular':celular, 
            'interes':interes
        }
        const res = await fetch(env.prod+'contacto',{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        })
        const data = await res.json()
        return data
    } catch (err) {
        console.log(err)
    }
}

const attend = async (id) => {
    try {
        let body = {
            'id':id,
        }
        const res = await fetch(env.prod+'contacto/attended',{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        })
        const data = await res.json()
        return data
    } catch (err) {
        console.log(err)
    }
}

const list = async ()=>{
    try {
        const res = await fetch(env.prod+'contacto',{
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        })
        const data = await res.json()
        return data
    } catch (error) {
        console.log(error)
    }
}

export default{
    create, list, attend
}