<template>
  <div id="navbar">
    <b-navbar fixed="top" toggleable="lg">
      <b-navbar-brand>
        <a href="/"
          ><img src="../../assets/logo-destino-tolima.png" alt=""
        /></a>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse v-if="visible" id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="/#start-section">{{ menus.start }}</b-nav-item>
          <b-nav-item href="/#ips">{{ menus.ips }}</b-nav-item>
          <b-nav-item href="/#professionals">{{
            menus.professionals
          }}</b-nav-item>
          <b-nav-item href="/#package">{{ menus.packages }}</b-nav-item>
          <b-nav-item href="/#testimonials">{{
            menus.testimonials
          }}</b-nav-item>
          <b-nav-item href="/#form-dt">{{ menus.form }}</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-form-select
            v-model="selected"
            v-on:change="selectLanguaje"
            :options="options"
          ></b-form-select>
          <!-- <div class="mt-3">Selected: <strong>{{ selected }}</strong></div> -->
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>


<script>
import { getLang, setLang } from "@/util/lang";

export default {
  name: "NavBar",
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    menus: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      selected: getLang(),
      options: [
        { value: "Es", text: "Es" },
        { value: "En", text: "En", selected: true },
      ],
    };
  },
  methods: {
    selectLanguaje() {
      setLang(this.selected);
    },
  },
  updated() {
    this.$emit("lang", this.selected);
  },
};
</script>

<style>
#navbar .navbar {
  padding-bottom: 0px;
  padding-top: 0px;
  background-color: #fff;
}
#navbar .nav-link {
  font-size: 1.6em;
  font-weight: bolder;
}
#navbar .nav-item {
  min-width: 5vw;
  display: grid;
  align-items: center;
}
#navbar .navbar-brand {
  margin-left: 1rem;
}
#navbar .nav-item:hover,
#navbar .nav-link:hover {
  background-color: #9c182b;
  color: #fff !important;
}
#navbar .navbar-expand .navbar-collapse,
#navbar .navbar-expand .navbar-nav {
  height: 100%;
}

@media screen and (max-width: 1400px) and (orientation: landscape) {
  #navbar .nav-link {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 360px) and (orientation: portrait) {
  #navbar .navbar-brand {
    margin-left: 0;
  }
}
</style>