<template>
  <div class="logout-button" @click="logout()">
      <b-icon icon="box-arrow-right"></b-icon>
      <p>Cerrar <br>Sesión</p>
    </div>
</template>

<script>
import Auth from '@/services/auth.service'
export default {
  methods:{
    logout(){
      Auth.logout().then(res=>{
        if (res.success) {
          localStorage.removeItem("key")
          this.$router.push("/login");
        }
      });              
    }
  }
}
</script>

<style>
.logout-button{
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  height: auto;
  padding: 0.5em 2em 0em 2.5em;
  border-bottom-left-radius: 30px;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  border-top: none;
  border-right: none;
  font-size: 1.2em;
  cursor: pointer;
}
.logout-button p{
  color: #9C182B;
  line-height: 1em;
  font-size: 1em;
  margin-bottom: .5em;
  text-align: left;
}
.logout-button .b-icon.bi{
  width: 40px;
  height: 40px;
  color: #9C182B;
  margin-right: .5em;
}
</style>