<template>
  <div class="button-submit">
    <b-button  size="lg" :type="btnType" :style="btnStyles">{{ text }}</b-button>
  </div>
</template>

<script> 
export default {
  props: {
    text: { type: String },
    btnWidth: {
      type: String,
      default: "400px"
    },
    btnHeight: {
      type: String,
      default: "50px",      
    },
    btnBackgroundColor: {
      type: String,
      default: "#404040"
    },
    btnBorderRadius: {
      type: String,
      default: "8px"
    },
    btnFontSize: {
      type: String,
      default: "1em"
    },
    btnType: {
      type: String,
      default: "submit"
    }
  },
  computed: {
    btnStyles(){
      return {
        "--btn-width": this.btnWidth,
        "--btn-height": this.btnHeight,
        "--btn-border-radius": this.btnBorderRadius,
        "--btn-background-color": this.btnBackgroundColor,
        "--btn-font-size": this.btnFontSize,
      }
    }
  }
}
</script>

<style>
.button-submit button{
  width: var( --btn-width );
  height: var( --btn-height );
  background-color: var( --btn-background-color ) !important;
  border-radius: var( --btn-border-radius ) !important;
  font-size: var( --btn-font-size ) !important;
  /* text-shadow: rgba(255, 255, 255, .7) -2px -1px 3px ; */
  border: none !important;
  font-weight: 800;
}

</style>