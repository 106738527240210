<template>
  <b-container fluid>
      <nav-bar :visible="false"></nav-bar>
      <b-row class="mt-4 pt-5">
        <b-col>
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfZnmeocDe0vMg0rJ4QeUSbTpcyntLfh3K0PGhFmRgbxKi-MA/viewform?embedded=true" width="640" height="3587" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>
        </b-col>
      </b-row>
  </b-container>
</template>

<script>
import NavBar from '@/views/landing/NavBar.vue'
export default {
  components: {NavBar}
}
</script>

<style>

</style>