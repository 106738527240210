<template>
  <section id="start-section">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="6000"
      indicators
      background="#ababab"
      img-width="1024"
      img-height="420"
      style="text-shadow: 1px 1px 2px #333"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- Slides with custom text -->
      
      <b-carousel-slide img-src="/assets/banners/background-1.jpg">
        <b-container fluid>
          <b-row>
            <b-col cols="lg-7" class="left-banner-1 col-md-12">
              <img src="/assets/banners/banner-1.png" alt="" />
              <h1>{{banners[0].title1}}<strong>{{banners[0].title2}}</strong></h1>
              <p>{{banners[0].description1}}<br>{{banners[0].description2}}</p>
              <b-row>
                <b-col cols="lg-1" class="col-2 px-sm-0 banner-icon"><img src="/assets/banners/icon-plus.png" alt="" /></b-col>
                <b-col cols="0" ><h2>|</h2></b-col>
                <b-col cols="lg-1" class="col-2 px-sm-0 banner-icon"><img src="/assets/banners/icon-balls.png" alt="" /></b-col>
                <b-col cols="0" ><h2>|</h2></b-col>
                <b-col cols="lg-1" class="col-2 px-sm-0 banner-icon"><img src="/assets/banners/icon-hiking.png" alt="" /></b-col>
                <b-col cols="0" ><h2>|</h2></b-col>
                <b-col cols="lg-1" class="col-2 px-sm-0 banner-icon"><img src="/assets/banners/icon-network.png" alt="" /></b-col>
                <b-col cols="0" ><h2>|</h2></b-col>
                <b-col cols="lg-1" class="col-2 px-sm-0 banner-icon"><img src="/assets/banners/icon-build.png" alt="" /></b-col>
              </b-row>
            </b-col>
            <b-col cols="5" class="right-banner-1">
              <a href="#footer-dt">
              <button-get-into
                class="button-banner-1"
                :btnHeight="'75px'"
                :btnWidth="'390px'"
                :btnAlign="'start'"
                :btnFontSize="'2em'"
                :btnColor="'#9C182B'"
                :btnBorderRadius="'20px'"
                :text="banners[0].btn"
                :btnBackgroundColor="'#fff'"
                :btnBoxShadow="'2px 2px 6px 2px #00000050'"
              ></button-get-into>
              </a>
            </b-col>
          </b-row>
        </b-container>
      </b-carousel-slide>
      <b-carousel-slide img-src="/assets/banners/background-2.png">
        <b-container fluid>
          <b-row>
            <b-col cols="lg-6" class="left-banner-2 col-md-12">
              <img src="/assets/banners/banner-2.png" alt="" />
              <p>
                <strong>{{banners[1].copy1}}</strong><br/>
                {{banners[1].copy2}}<strong>{{banners[1].copy3}}</strong><span>{{banners[1].copy4}},</span><br/>
                <strong>{{banners[1].copy5}}</strong>{{banners[1].copy6}}<strong>{{banners[1].copy7}}</strong><span>{{banners[1].copy8}}</span>
              </p>
              <a href="#form-dt">
              <button-get-into
                :btnHeight="'80px'"
                :btnWidth="'390px'"
                :btnAlign="'start'"
                :btnFontSize="'2em'"
                :btnColor="'#00A098'"
                :btnBorderRadius="'15px'"
                :text="banners[1].btn"
                :btnBackgroundColor="'#fff'"
                :btnBoxShadow="'2px 2px 6px 2px #00000050'"
              ></button-get-into>
              </a>
            </b-col>
            <b-col cols="6" class="right-banner-2">
              <img src="/assets/banners/people-2.png" alt="" />
            </b-col>
          </b-row>
        </b-container>
      </b-carousel-slide>
      <b-carousel-slide img-src="/assets/banners/background-3.png" class="carousel-slide-3">
      <!-- <b-carousel-slide class="carousel-slide-3"> -->
        <b-container fluid>
          <b-row>
            <b-col cols="lg-6" class="left-banner-3 col-md-12">
              <img src="/assets/banners/banner-3.png" alt="" />
              <p>{{banners[2].copy1}}</p>
            </b-col>
            <b-col cols="6" class="right-banner-3">
              <a href="#form-dt">
              <button-get-into
                class="button-banner-3"
                :btnHeight="'75px'"
                :btnWidth="'390px'"
                :btnAlign="'end'"
                :btnFontSize="'2em'"
                :btnColor="'#00a098'"
                :btnBorderRadius="'20px'"
                :text="banners[2].btn"
                :btnBackgroundColor="'#fff'"
                :btnBoxShadow="'2px 2px 6px 2px #00000050'"
              ></button-get-into>
              </a>
            </b-col>
          </b-row>
        </b-container>
      </b-carousel-slide>
      <b-carousel-slide img-src="/assets/banners/background-4.jpg">
        <b-container fluid>
          <b-row class="justify-content-start">
            <b-col cols="lg-10" class="left-banner-4 pl-4 col-md-12">
              <b-row class="text-align-center" align-v="center">
                <b-col cols="lg-6" class="col-md-12"><h1>Ibagué</h1></b-col>
                <b-col cols="lg-6" class="col-md-12"><h5>{{banners[3].copy1}}<br><strong>{{banners[3].copy2}}</strong></h5></b-col>
              </b-row>
              <b-row>
                <b-col>
                  <p>{{banners[3].copy3}}</p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="10" >
              <a href="#footer-dt">
                <button-get-into class="btn-banner-4" :text="banners[3].btn" :btnBackgroundColor="'#e84e1c'" :btnFontSize="'2em'" :btnWidth="'420px'" :btnHeight="'80px'" :btnBorderRadius="'15px'"></button-get-into>
              </a>
            </b-col>
            <b-col cols="2">
              <img class="img-banner-4" src="/assets/banners/banner-4.png" alt="" />
            </b-col>
          </b-row>
        </b-container>
      </b-carousel-slide>
    </b-carousel>
  </section>
</template>

<script>
import ButtonGetInto from "@/components/buttons/ButtonGetInto.vue";
export default {
  props:{
    banners:{
      type: Array,
      default: null
    }
  },
  components: {
    ButtonGetInto,
  },
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>

<style>
#start-section {
  margin-top: 73px;
}
.carousel-caption {
  width: 100vw;
  bottom: 0px;
  left: auto;
  right: auto;
  margin: 0px auto;
}
.carousel-inner{
  background-color: #fff;
}
.button-banner-1 button{
  position: absolute;
  left: 5em;
  top: auto;
  bottom: 2em;
}
.left-banner-1 {
  text-align: start;
  padding-bottom: 4em;
}
.left-banner-1 {
  padding-left: 8em;
}

.left-banner-1 img {
  width: 100%;
}
.left-banner-1 h2, .left-banner-1 h1, .left-banner-2 p, .left-banner-1 p, .left-banner-4 h1, .left-banner-4 h5, .left-banner-4 p {
  text-shadow: none;
}
.left-banner-1 h1{
  color: #9C182B;
  font-size: 6em;
}
.left-banner-1 h1 strong{
  color: #000;
  font-weight: bolder;
}
.left-banner-1 .col-1{
  padding: 0;
}
.left-banner-1 h2{
  color: #bbb;
  font-size: 3.8em;
  margin: 0;
}
.left-banner-1 p {
  text-align: left;
  color: #777;
  font-style: italic;
  font-size: 1.8em;
}
.left-banner-2 span {
  text-decoration: underline;
  text-decoration-color: #E6F6F5;
}
.left-banner-2{
  padding-left: 12em;
  padding-bottom: 3em;
  text-align: left;
}
.right-banner-2{
  display: flex;
}
.left-banner-2 img {
  width: 130%;
}
.right-banner-2 img {
  width: 110%;
  height: auto;
  margin: auto;
  margin-bottom: 0px;
}
.left-banner-2 p {
  text-align: left;
  font-size: 2.8em;
  color: #303030;
  font-weight: 500;
  margin-bottom: 0.5em;
  line-height: 0.95em;
}

.carousel-slide-3 {
  background: white !important;
  background-image: url(/assets/banners/people-3.png) !important;
    background-size: 50% !important;
    background-position: top right !important;
    background-repeat: no-repeat !important;
    margin-top: 5em;
}
.carousel-slide-3 .img-fluid{
  margin-top: 4em;
}
.left-banner-3 img{
  width: 80%;
  margin-bottom: 16vh;
}
.left-banner-3 p{
  font-size: 2.5em;
  text-shadow: none;
  text-align: left;
  padding-left: 4em;
  margin-bottom: 2em;
  line-height: 1;
}
.button-banner-3 button{
  position: absolute;
  left: 11em;
  top: auto;
  bottom: 2.5em;
}

.left-banner-4 h1 {
  font-size: 12em;
  color: #e84e1c;
  font-weight: bolder;
  text-align: left;
  margin: 0;
}
.left-banner-4 h5,
  .left-banner-4 p,
  .left-banner-4 h5 strong{
    color: #000;
  }
.left-banner-4 h5{
  text-align: left;
  font-size: 3.2em;
  margin-top: .7em;
}
.left-banner-4 h5 strong{
  font-style: italic;
}
.left-banner-4 p{
  font-size: 2.7em;
  margin-bottom: 2em;
  text-align: left;
}

.img-banner-4{
  padding-bottom: 2em;
}
.btn-banner-4{
  padding-top: 3em;
}
/* ------------------------------------------Responsive--------------------------------------- */
@media screen and (max-width: 1400px) and (orientation: landscape) { 
  .left-banner-2 p{
    font-size: 1.8em;
  }  
  .left-banner-1{
    padding-bottom: 2em;
  }
  .left-banner-1 h1{
    font-size: 4em;
  }
  .left-banner-1 h2{
    font-size: 2.8em;
  }
  .left-banner-1 p{
    font-size: 1.2em;
  }
  .button-banner-1 button{
    bottom: 1em;
  }

  .right-banner-2 img{
   margin-bottom: -5px; 
  }
  .button-banner-3 button{
    position: absolute;
    left: 6em;
    top: auto;
    bottom: 1.5em;
  }
  .left-banner-3 p{
    font-size: 2.1em;
    padding-left: 3.5em;
    margin-bottom: 1.5em;
  }
  .left-banner-3 img{
    margin-bottom: 3em;
  }
  .carousel-slide-3 .img-fluid{
    margin-top: 3em;
  }

  .img-banner-4{
    padding-bottom: 1em;
    width: 120px;
  }
  .btn-banner-4{
    padding-top: 1em;
  }
  .left-banner-4 p {
    font-size: 1.7em;
    margin-bottom: 1em;
  }
  .left-banner-4 h1{
    font-size: 9em;
  }
  .left-banner-4 h5{
    font-size: 2.5em;
  }
}

@media screen and (max-width: 600px) and (orientation: portrait) { 
  #start-section {
    height: 70vh;
  }
  #start-section .img-fluid{
    height: 70vh;
    min-width: 170vw;
  }
  #start-section .carousel-caption{
    padding-bottom: 4em;
  }
  .button-banner-1 button{
    left: 1em;
    bottom: 0em;
  }
  .left-banner-1 {
    padding-left: 1.5em;
    height: 50vh;
  }
  .left-banner-1 p {
    font-size: 1em;
  }
  .left-banner-1 h1 {
    font-size: 2em;
  }
  .left-banner-1 h2{
    font-size: 2.6em;
  }
  #start-section .button-get-into button{
    width: 320px !important;
    font-size: 1.5em !important;
  }
  #start-section .banner-icon{
    padding: 0;
  }

  .left-banner-2{
    padding-left: 2em;
    padding-bottom: 2em;
  }
  .left-banner-2 p{
    font-size: 1.6em;
    margin-bottom: 2em;
  }
  .left-banner-2 img{
    width: 100%;
    margin-bottom: 2em;
  }
  .right-banner-2 img{
    display: none;
  }

  .left-banner-4 p{
    font-size: 1.5em;
    margin-bottom: 0;
  }
  #start-section .img-banner-4 {
    display: none;
  }
  .left-banner-4 h1 {
    font-size: 6em;
  }
  .left-banner-4 h5 {
    font-size: 1.8em;
  }
  .left-banner-1 {
    height: 53vh;
  }
  .left-banner-2 {
    padding-left: 1em;
  }
  .left-banner-4 h1{
    font-size: 5em;
  }
}
@media screen and (max-width: 440px) and (orientation: portrait) {  
  .carousel-slide-3{
    background-position-y: center !important;
    background-size: 80% !important;
  }
  .carousel-slide-3 .img-fluid{
    height: 40vh !important;
    margin-top: 20vh;
  }
  .left-banner-3 p{
    font-size: 1.2em;
    padding-left: 2vw;
    margin-bottom: 30vh;
    text-shadow: 1px 1px 2px #555;
  }
  .left-banner-3 img{
    margin-bottom: 4vh;
    width: 100%;
  }
  .button-banner-3 button{
    margin-left: calc( 100% - 420px );
    bottom: 0em;
  }
}
</style>