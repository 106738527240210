import env from '@/environment/env'

const listDepartments = async (idCountry)=>{
    try {
        const res = await fetch(env.prod+'departamento/'+idCountry+'/pais',{
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            }
        })
        const data = await res.json()
        return data
    } catch (error) {
        console.log(error)
    }
}
const listCities = async (idDepartment)=>{
    try {
        const res = await fetch(env.prod+'ciudad/'+idDepartment+'/departamento',{
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            }
        })
        const data = await res.json()
        return data
    } catch (error) {
        console.log(error)
    }
}
const getLocation = async (idCity)=>{
    try {
        //ciudad/1/departamento/pais
        const res = await fetch(env.prod+'ciudad/'+idCity+'/departamento/pais',{
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            }
        })
        const data = await res.json()
        return data
    }
    catch(error){
        console.log(error)
    }
}

export default{
    listDepartments, listCities, getLocation
}