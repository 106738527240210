<template>
  <section id="country">
    <b-container fluid class="pt-4 pb-2 mb-3 mr-0 ml-0">
      <b-row >
        <b-col class="justify-content-center">
          <h2 class="mt-5">{{countries[0].section_title}}</h2>
        </b-col>
      </b-row>
      <b-row class="justify-content-center mt-2">
        <b-col cols="10">
          <carousel
            :centerMode="true"
            :autoplayTimeout="5000"
            :perPageCustom="[
              [300, 1],
              [480, 1],
              [768, 2],
              [1080, 3],
              [1480, 4],
            ]"
            :loop="true"
            :autoplay="true"
            :navigationEnabled="true"
            navigationNextLabel='<img src="" >'
            navigationPrevLabel='<img src="" >'
          >
            <slide v-for="i in countries" :key="i.id">
              <country-card :country="i" :btnText="countries[0].see_more"></country-card> 
            </slide>
          </carousel>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import CountryCard from "@/components/cards/CountryCard";
import { Carousel, Slide } from "vue-carousel";

export default {
  props: {
    countries: {
      type: Array,
      default: null,
    },
  },
  components: {
    CountryCard,
    Carousel,
    Slide,
  },
};
</script>

<style>
#country {
  height: 80vh;
}
#country h2 {
  color: #00a098;
  font-size: 3.5em; 
  font-weight: bold;
}
#country p {
  font-size: 1em;
}
#country ul {
  padding-top: 40px;
  padding-bottom: 40px;
}
#country .btn-rigth {
  right: -3vw;
}
#country .btn-left {
  left: -3vw;
}
#country .VueCarousel-navigation-button::before {
  content: "";
  position: absolute;
  top: 8px;
  height: 50px;
  width: 40px;
}

#country .VueCarousel-navigation-next::before {
  background: url('/assets/arrow-r-l.png');
  background-repeat: no-repeat;
  background-size: contain;
  right: -30px;
}

#country .VueCarousel-navigation-prev::before {
  background: url('/assets/arrow-l-l.png');
  background-repeat: no-repeat;
  background-size: contain;
  left: 20px;
}

@media screen and (max-width: 1400px) and (orientation: landscape) { 
  #country {
    height: 90vh;
  }
  #country h2{
    font-size: 3em;
    margin-top: 0em !important;
  }
  #country article .price{
    font-size: .8em;
  }
  #country .VueCarousel-navigation-prev::before {
    left: -10px;
  }
}
@media screen and (max-width: 600px) and (orientation: portrait) { 
  #country {
    height: 70vh;
  }
  #country h2{
    font-size: 2em;
  }
  #country .VueCarousel-navigation-prev::before {
    left: -20px;
  }
}
@media screen and (max-width: 4000px) and (orientation: portrait) {
  #country .card-img-top{
    width: 32vw;
    height: 32vw; 
  }
}
</style>