<template>
  <div class="w-100 p-2 mx-auto input-icon">
    <b-input-group :style="txtStyles">
      <b-input-group-prepend>
        <span class="input-group-text"><b-icon :icon="txtIcon" variant="secondary"></b-icon></span>
      </b-input-group-prepend>
      <b-form-input
        :type="type"
        v-model="text"
        :placeholder="placeholder"
        :state="updateState"
      ></b-form-input>
    </b-input-group>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "Ingrese su respuesta...",
    },
    type: {
      type: String,
      default: "text",
    },
    minimum: {
      type: Number,
      default: 2,
    },
    txtHeight: {
      type: String,
      default: "50px",
    },
    txtBackground: {
      type: String,
      default: "white",
    },
    txtShadow: {
      type: String,
      default: "none",
    },
    txtBorder: {
      type: String,
      default: "1px solid #ced4da",
    },
    txtBorderRadius: {
      type: String,
      default: "8px",
    },
    txtColor: {
      type: String,
      default: "#495057",
    },
    txtFontSize: {
      type: String,
      default: "1rem",
    },
    txtIcon: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      text: "",
    };
  },
  updated() {
    this.$emit("text", this.text);
  },
  computed: {
    updateState() {
      if (
        this.type == "email" &&
        this.text.includes("@") &&
        this.text.includes(".")
      ) {
        return true;
      } else if (
        this.type == "text" ||
        this.type == "number" ||
        this.type == "password"
      ) {
        return this.text.toString().length > this.minimum ? true : null;
      } else {
        return null;
      }
    },
    txtStyles() {
      return {
        "--txt-color": this.txtColor,
        "--txt-height": this.txtHeight,
        "--txt-border": this.txtBorder,
        "--txt-shadow": this.txtShadow,
        "--txt-font-size": this.txtFontSize,
        "--txt-background": this.txtBackground,
        "--txt-border-radius": this.txtBorderRadius,
      };
    },
  },
};
</script>

<style>
input{
  border: none !important;
  box-shadow: none !important;
}
.input-icon .input-group-text{
  border: none;
  background-color: white;
  border-radius: var(--txt-border-radius) !important;
}
.input-icon .input-group > .form-control{
  height: 100%;
  border: none;
  outline: none !important;
  box-shadow: none;
  margin-right: 1em;
}
.input-icon .input-group{
  color: var(--txt-color) !important;
  height: var(--txt-height) !important;
  border: var(--txt-border) !important;
  box-shadow: var(--txt-shadow) !important;
  font-size: var(--txt-font-size) !important;
  background-color: var(--txt-background) !important;
  border-radius: var(--txt-border-radius) !important;
}
</style>