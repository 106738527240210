<template>
  <div class="button-get-into" :style="btnStyles">
    <b-button size="lg" @click='onclick' :btnType="btnType">{{ text }}</b-button>
  </div>
</template>

<script> 
export default {
  props: {
    onTap: { type: Function},
    text: { type: String },
    btnWidth: {
      type: String,
      default: "400px"
    },
    btnHeight: {
      type: String,
      default: "50px",      
    },
    btnBackgroundColor: {
      type: String,
      default: "#404040"
    },
    btnBorderRadius: {
      type: String,
      default: "8px"
    },
    btnBoxShadow: {
      type: String,
      default: "none"
    },
    btnFontSize: {
      type: String,
      default: "1em"
    },
    btnType: {
      type: String,
      default: "submit"
    },
    btnColor: {
      type: String,
      default: "#fff"
    },
    btnAlign: {
      type: String,
      default: "center"
    }
  },
  methods: {
    onclick() {
      this.onTap();
    }
  },
  computed: {
    btnStyles(){
      return {
        "--btn-align": this.btnAlign,
        "--btn-width": this.btnWidth,
        "--btn-color": this.btnColor,
        "--btn-height": this.btnHeight,
        "--btn-font-size": this.btnFontSize,
        "--btn-box-shadow": this.btnBoxShadow,
        "--btn-border-radius": this.btnBorderRadius,
        "--btn-background-color": this.btnBackgroundColor,
      }
    }
  }
}
</script>

<style>
.button-get-into{
  text-align: var( --btn-align );
}
.button-get-into button{
  color: var( --btn-color );
  width: var( --btn-width );
  height: var( --btn-height );
  font-size: var( --btn-font-size ) !important;
  box-shadow: var( --btn-box-shadow ) !important;
  border-radius: var( --btn-border-radius ) !important;
  background-color: var( --btn-background-color ) !important;
  line-height: 0;
  text-shadow: none;
  font-style: italic;
  padding: 0 !important;
  border: none !important;
  font-weight: bold !important;  
}
.button-get-into button:hover{
  color: var( --btn-color );
}
</style>