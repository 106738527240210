<template>
  <section id="footer-dt">
    <b-container fluid class="pb-5">
      <b-row class="row-links">
        <b-col cols="md-12" class="col-lg-7">
          <h1 class="footer-message">
            <strong>Déjate sorprender</strong> con nuestras <br />rutas
            <strong>Turísticas</strong>
          </h1>
        </b-col>
      </b-row>
      <b-row align-v="end" class="pr-5 mb-5">
        <b-col cols="md-12  text-lg-right" class="col-xl-4 col-right">
          <img src="../../assets/logo-destino-tolima-w.png" alt=""><br>
          <span class="mb-1"><a target="_blank" href="https://tolima.travel/exploratolima/es">tolima.travel/exploratolima/es</a></span>
        </b-col>
        <b-col cols="lg-4 text-lg-left pl-4" class="col-left col-md-12"> 
          <div class="d-flex mb-1" >
            <a target="_blank" href="https://www.facebook.com/destinotolimacol"><img src="../../assets/icono-facebook.png" alt="" /></a>
            <a target="_blank" href="https://api.whatsapp.com/send/?phone=573242802435&text&app_absent=0"><img src="../../assets/icono-wp.png" alt="" /></a>
            <a target="_blank" href=""><img src="../../assets/icono-tw.png" alt="" /></a> 
          </div>
          <span><a target="_blank" href="https://www.rutaaltolima.com">rutaaltolima.com</a></span>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {};
</script>

<style>
#footer-dt {
  height: 730px;
  background-image: url("../../assets/1400x450.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  text-align: left;
  display: grid;
  align-items: center;
}
#footer-dt .container-fluid .row-links {
  height: 75%;
}
#footer-dt .container-fluid {
  height: 700px;
  padding-top: 3em;
  padding-left: 3em;
}
#footer-dt p,
#footer-dt a {
  color: #fff;
}
#footer-dt h1 {
  color: #fff;
  margin-left: 2em;
}
#footer-dt p {
  font-size: 1.5em;
}

#footer-dt .col-right img {
  width: 320px;
}
#footer-dt .col-left img {
  width: 50px;
  height: auto;
  margin: 5px;
}
#footer-dt span{
  background-color: #fff;
  border-radius: 20px;
  padding: .5em 1.2em;
}
#footer-dt span a{
  color: #9C182B;
  font-size: 1.7em;
}
#footer-dt .row-links{
  padding-left: 3em;
}

@media screen and (max-width: 1400px) and (orientation: landscape) {
  #footer-dt {
    height: 520px;
  }
  #footer-dt .container-fluid {
    height: 480px;
    padding-top: .5em;
  }
  #footer-dt h1{
    font-size: 2em;
    margin-left: 1em;
  }
  #footer-dt span a{
    font-size: 1.2em;
  }
  #footer-dt .col-right img {
    width: 260px;
  }
  #footer-dt .col-left img {
    width: 40px;
    height: auto;
  }
}
@media screen and (max-width: 600px) and (orientation: portrait) {
  #footer-dt {
    background-size: cover;
    background-position: inherit;
  }
  #footer-dt h1{
    font-size: 1.2em;
    margin-left: 0em;
  }
  #footer-dt .row-links{
    padding-left: 0em;
  }
  #footer-dt .col-right img{
    width: 220px;
  }
  #footer-dt .col-left img{
    width: 40px;
  }
  #footer-dt .col-left {
    margin-top: 2em;
  }
  
  #footer-dt span {
    padding: .3em 1em;
  }
  #footer-dt span a{
    font-size: 1em;
  }
  #footer-dt .container-fluid {
    padding-left: 1em;
    width: 100vw;
  }
  #footer-dt .container-fluid .row-links {
    height: 60%;
  }
}
</style>