<template>
  <div class="w-100 p-2 mx-auto input-text">
      <b-form-input
        :type="type"
        v-model="text"
        :placeholder="placeholder"
        :state="updateState"
        :readonly="readonly"
        :style="txtStyles"
        :required="required"
      ></b-form-input>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "Ingrese su respuesta...",
    },
    required: {
      type: Boolean,
      default: true
    }, 
    type: {
      type: String,
      default: "text",
    },
    readonly: {
      type: Boolean,
      default: false
    },
    minimum: {
      type: Number,
      default: 2,
    },
    txtHeight: {
      type: String,
      default: "50px",
    }, 
    txtBackground: {
      type: String,
      default: "#00a09815",
    },
    txtShadow: {
      type: String,
      default: "none",
    },
    txtBorder: {
      type: String,
      default: "1px solid #ced4da",
    },
    txtBorderRadius: {
      type: String,
      default: "8px",
    },
    txtColor: {
      type: String,
      default: "#495057",
    },
    txtFontSize: {
      type: String,
      default: "1rem",
    },
  },
  data() {
    return {
      text: "",
    };
  },
  updated() {
    this.$emit("text", this.text);
  },
  computed: {
    updateState() {
      if (
        this.type == "email" &&
        this.text.includes("@") &&
        this.text.includes(".")
      ) {
        return true;
      } else if (
        this.type == "text" ||
        this.type == "number" ||
        this.type == "password"
      ) {
        return this.text.toString().length > this.minimum ? true : null;
      } else {
        return null;
      }
    },
    txtStyles() {
      return {
        "--txt-color": this.txtColor,
        "--txt-height": this.txtHeight,
        "--txt-border": this.txtBorder,
        "--txt-border-radius": this.txtBorderRadius,
        "--txt-shadow": this.txtShadow,
        "--txt-font-size": this.txtFontSize,
        "--txt-background": this.txtBackground,
      };
    },
  },
};
</script>

<style>
.input-text .form-control {
  text-align: center;
  color: var(--txt-color) !important;
  height: var(--txt-height) !important;
  border: var(--txt-border) !important;
  border-radius: var(--txt-border-radius) !important;
  box-shadow: var(--txt-shadow) !important;
  font-size: var(--txt-font-size) !important;
  background-color: var(--txt-background) !important;
}
</style>