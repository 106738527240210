<template>
  <b-sidebar
      id="sidebar-1"
      title="Menu"
      no-header
      no-slide
      :no-close-on-route-change="true"
      :visible="true"
      :no-close-on-esc="true"
    >
      <div class="mt-5 mb-3">
        <img
          src="../../assets/logo-administrador.png"
          width="40%"
          height="auto"/>
        <h3>{{user.primer_nombre+' '+user.primer_apellido}}</h3>
      </div>
      <!-- <b-button v-b-toggle.sidebar-1 variant="primary" block >Close Sidebar</b-button> -->
        <!---------------------------------------- Usuarios ------------------------------------->
        <div v-if="user.idusuario == 1">
        <button-menu  :text="'Gestionar Usuario'" :toggles="'usuario-a usuario-b usuario-c usuario-d usuario-e'"></button-menu>
          <b-collapse id="usuario-a" class="mt-1">
              <button @click="swapComponent('create-user')">Crear Usuario</button>
          </b-collapse>
          <b-collapse id="usuario-b" class="mt-1">
              <button @click="swapComponent('permissions-user')">Otorgar Permisos</button>
          </b-collapse>
          <b-collapse id="usuario-c" class="mt-1">
              <button @click="swapComponent('create-rol')">Crear Roles</button>
          </b-collapse>
          <b-collapse id="usuario-d" class="mt-1">
              <button @click="swapComponent('list-users')">Listar Usuarios</button>
          </b-collapse>
        </div>
        <!-- <b-collapse id="usuario-e" class="mt-1">
            <button @click="swapComponent('edit-user')">Editar Usuario</button>
        </b-collapse> -->
        <!---------------------------------------- Paciente ------------------------------------->
        <button-menu :text="'Paciente'" :toggles="'paciente-a paciente-b'"></button-menu>
        <div v-if="user.idusuario == 1 || havePermission(6)">
          <b-collapse id="paciente-a" class="mt-1">
            <button @click="swapComponent('create-patient')">Crear Paciente</button>
          </b-collapse>
        </div>
        <div v-if="user.idusuario == 1 ||  havePermission(5)">
          <b-collapse id="paciente-b" class="mt-1">
            <button @click="swapComponent('list-patients')">Listar Pacientes</button>
          </b-collapse>
        </div>
        <!----------------------------------------- Agenda --------------------------------------->
        <button-menu :text="'Agenda'" :toggles="'agenda-a agenda-b'"></button-menu>
        <div v-if="user.idusuario == 1 || havePermission(14)">
          <b-collapse id="agenda-a" class="mt-1">
            <button @click="swapComponent('create-diary')">Crear Agenda</button>
          </b-collapse>
        </div>
        <div v-if="user.idusuario == 1 || havePermission(13)">
          <b-collapse id="agenda-b" class="mt-1">
            <button @click="swapComponent('list-diaries')">Listar Agenda</button>
          </b-collapse>
        </div>
        <!----------------------------------------- Pagos --------------------------------------->
        <button-menu :text="'Pagos'" :toggles="'pago-a pago-b'"></button-menu>            
        <div v-if="user.idusuario == 1 || havePermission(28)">
          <b-collapse id="pago-a" class="mt-1">
            <button @click="swapComponent('create-payment')">Crear Pago</button>
          </b-collapse>
        </div>
        <div v-if="user.idusuario == 1 || havePermission(27)">
          <b-collapse id="pago-b" class="mt-1">
            <button @click="swapComponent('list-payments')">Listar Pagos</button>
          </b-collapse>
        </div>
        <!-------------------------------------- Procedimientos ------------------------------------->
        <button-menu :text="'Procedimiento'" :toggles="'procedimiento-a procedimiento-b'"></button-menu>            
        <div v-if="user.idusuario == 1 || havePermission(66)">
          <b-collapse id="procedimiento-a" class="mt-1">
            <button @click="swapComponent('create-procedure')">Gestionar Procedimientos</button>
          </b-collapse>
        </div>
        <div v-if="user.idusuario == 1 || havePermission(65)">
          <b-collapse id="procedimiento-b" class="mt-1">
            <button @click="swapComponent('list-procedures')">Resumen Procedimientos</button>
          </b-collapse>
        </div>
        <!--------------------------------------- Formulario ------------------------------------->
        <b-button  @click="swapComponent('list-forms')" class="button-menu mt-1">Formulario de contacto</b-button>
        <!-- <contact-menu :onTap="swapComponent('create-patient')"></contact-menu> -->
    </b-sidebar>
</template>

<script>
import Auth from '@/services/auth.service'
import ButtonMenu from '@/components/buttons/ButtonMenu';
export default {
  data(){
    return {
      user: {},
      permissions: []
    } 
  },
  props: {
    component: {type: Function}
  },
  components: {
    ButtonMenu,
  },
  methods: {
    swapComponent: function (component) {
      this.$emit("swap", component);
    },   
    getUserData(){
      Auth.getUserData()
      .then(res => {
        this.user = res.data
        })
      .catch()
    },
    getPermessions(){
      Auth.getUserPermissions()
      .then(res => this.permissions = res.data)
      .catch()
    },
    havePermission(number){
      if(this.permissions){
        return this.permissions.filter(function (element){
          return element.id == number
        })[0] ? true: false;
      }
    }
  },
  mounted() {
    this.getUserData()
    this.permissions = this.getPermessions()
  },
}
</script>