<template>
  <div class="w-100 p-2 mx-auto">
    <b-form-datepicker
      show-decade-nav
      v-model="value"
      class="mt-2"
      :style="txtStyles"
      :placeholder="placeholder"
      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
    ></b-form-datepicker>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
    };
  },
  props: {
    placeholder: {
      type: String,
      default: "Fecha de nacimiento",
    },
    txtShadow: {
      type: String,
      default: "-3px 3px 0px 2px #00a098",
    },
    txtBorder: {
      type: String,
      default: "none",
    },
  },
  updated() {
    this.$emit("value", this.value);
  },
  computed: {
    txtStyles() {
      return {
        "--txt-shadow": this.txtShadow,
        "--txt-border": this.txtBorder,
      };
    },
  },
};
</script>

<style scope>
.b-form-datepicker {
  outline: none;
  width: 100%;
  text-align: center;
  height: 50px !important;
  margin-top: 0 !important;
  border-radius: 8px;
  border: var(--txt-border) !important;
  box-shadow: var(--txt-shadow) !important;
}
.b-form-datepicker label {
  padding: 1em;
}
</style>