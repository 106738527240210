<template>
  <section id="testimonials">
    <b-container fluid class="pt-4 pb-2 mt-5 mb-3 mr-0 ml-0">
      <b-row class="justify-content-center">
        <b-col cols="lg-10" class="md-12">
          <div class="testimonials-box">
            <h2>{{testimonials[0].section_title}}</h2>
            <carousel
              :autoplayTimeout="5000"
              :perPageCustom="[
                [350, 1],
                [380, 1],
                [480, 1],
                [768, 2],
                [1080, 3],
                [1480, 3],
              ]"
              :loop="true"
              :autoplay="true"
              :navigationEnabled="true"
              navigationNextLabel='<img src="" >'
              navigationPrevLabel='<img src="" >'
            >
              <slide v-for="i in testimonials" :key="i.id">
                <testimonial-card :testimonialDt="i"></testimonial-card>
              </slide>
            </carousel>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import TestimonialCard from "@/components/cards/TestimonialCard";

export default {
  props: {
    testimonials: {
      type: Array,
      default: null,
    },
  },
  components: {
    TestimonialCard,
    Carousel,
    Slide,
  },
};
</script>

<style>
#testimonials {
  background-color: #e6f6f5;
  padding-top: 5vh;
}
#testimonials .testimonials-box {
  border: solid 8px #00a098;
  border-radius: 3.5em;
  padding-top: 4em;
}
#testimonials h2 {
  color: #00a098;
  font-size: 3.5em;
  font-weight: bold;
  background-color: #e6f6f5;
  margin: auto;
  position: absolute;
  left: calc(50% - 11vw);
  top: -0.7em;
  padding: 0 .5em;
}
#testimonials p {
  font-size: 0.8em;
}
#testimonials ul {
  padding: 40px;
}
#testimonials li {
  width: 20%;
}
#testimonials li article {
  display: block;
  margin-left: 0em;
}
#testimonials .VueCarousel-navigation-next {
  right: -4vw;
}
#testimonials .VueCarousel-navigation-prev {
  left: -4vw;
}
#testimonials .VueCarousel-navigation-button::before {
  content: "";
  position: absolute;
  top: 8px;
  height: 50px;
  width: 40px;
}

#testimonials .VueCarousel-navigation-next::before {
  background: url("/assets/arrow-r-d.png");
  background-repeat: no-repeat;
  background-size: contain;
  right: -16px;
}

#testimonials .VueCarousel-navigation-prev::before {
  background: url("/assets/arrow-l-d.png");
  background-repeat: no-repeat;
  background-size: contain;
  left: -16px;
}
@media screen and (max-width: 1400px) and (orientation: landscape) {
  #testimonials h2 {
    font-size: 2.5em;
  }
  #testimonials .VueCarousel-navigation-next::before {
    right: 30px;
  }
  #testimonials .VueCarousel-navigation-prev::before {
    left: 80px;
  }
  #testimonials .VueCarousel-navigation-next {
    right: 0;
  }
}
@media screen and (max-width: 600px) and (orientation: portrait) {
  #testimonials h2 {
    font-size: 1.8em;
    margin: auto;
    left: calc(50% - 112px);
  }
  #testimonials article h4 {
    font-size: 1.2em;
  }
  #testimonials .card-body {
    padding: 0.5em;
  }
  #testimonials .VueCarousel-navigation-next::before {
    right: 10px;
  }
  #testimonials .VueCarousel-navigation-prev::before {
    left: 15px;
  }
}
@media screen and (max-width: 360px) and (orientation: portrait) {
  #testimonials h2{
    left: calc(50% - 85px);
    font-size: 1.5em;
  }
}
</style>