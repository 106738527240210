<template>
  <div class="admin-body">
    <div class="header-admin">
      <h1>Otorgar Permisos</h1>
      <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
    </div>
    <b-container> 
      <b-form @submit.stop.prevent="addPermissions">
        <b-row class="mt-4 mb-4 justify-content-center">
          <b-col cols="4">
            <input-select
              :placeholder="'Selecciona un rol'"
              :options="roles"
              @value="idRole = $event"
            ></input-select>
          </b-col>
        </b-row>
        <b-row align-v="center" class="permission-row pt-3 mb-1 pl-3">
          <b-col class="text-left">
            <p><strong>Paciente</strong></p>
          </b-col>
          <b-col>
            <input-checkbox @value="patientList = $event" :text="'Listar'"></input-checkbox>
          </b-col>
          <b-col>
            <input-checkbox @value="patientCreate = $event" :text="'Crear'"></input-checkbox>
          </b-col>
          <b-col>
            <input-checkbox @value="patientEdit = $event" :text="'Editar'"></input-checkbox>
          </b-col>
        </b-row>
        <b-row align-v="center" class="permission-row pt-3 mb-1 pl-3">
          <b-col class="text-left">
            <p><strong>Agenda</strong></p>
          </b-col>
          <b-col>
            <input-checkbox @value="diaryList = $event" :text="'Listar'"></input-checkbox>
          </b-col>
          <b-col>
            <input-checkbox @value="diaryCreate = $event" :text="'Crear'"></input-checkbox>
          </b-col>
          <b-col>
            <input-checkbox @value="diaryEdit = $event" :text="'Editar'"></input-checkbox>
          </b-col>
        </b-row>
        <b-row align-v="center" class="permission-row pt-3 mb-1 pl-3">
          <b-col class="text-left">
            <p><strong>Pagos</strong></p>
          </b-col>
          <b-col>
            <input-checkbox @value="paymentList = $event" :text="'Listar'"></input-checkbox>
          </b-col>
          <b-col>
            <input-checkbox @value="paymentCreate = $event" :text="'Crear'"></input-checkbox>
          </b-col>
          <b-col>
          </b-col>
        </b-row>
        <b-row align-v="center" class="permission-row pt-3 mb-1 pl-3">
          <b-col class="text-left">
            <p><strong>Procedimientos</strong></p>
          </b-col>
          <b-col>
            <input-checkbox @value="procedureList = $event" :text="'Listar'"></input-checkbox>
          </b-col>
          <b-col>
            <input-checkbox @value="procedureCreate = $event" :text="'Crear'"></input-checkbox>
          </b-col>
          <b-col>
          </b-col>
        </b-row>
        <b-row align-v="center" class="permission-row pt-3 mb-1 pl-3">
          <b-col class="text-left">
            <p><strong>Formulario de contacto</strong></p>
          </b-col>
          <b-col>
            <input-checkbox @value="formList = $event" :text="'Listar'"></input-checkbox>
          </b-col>
          <b-col>
          </b-col>
          <b-col>
          </b-col>
        </b-row>
        <b-row class="mt-5 justify-content-center">
          <b-col>
            <button-submit
              :btnBackgroundColor="'#00a098'"
              :text="'Guardar'"
              :btnWidth="'300px'"
              :btnFontSize="'1.6em'"
            ></button-submit>
          </b-col>
        </b-row>
      </b-form>
      <b-row class="pt-5">
        <b-col>
          <b-alert
            :show="dismissCountDown"
            dismissible
            :variant="typeAlert"
            @dismissed="dismissCountDown = 0"
            @dismiss-count-down="countDownChanged"
          >
            {{message}} {{ dismissCountDown }}
          </b-alert>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Role from "@/services/role.service";
import InputSelect from "@/components/inputs/InputSelect";
import InputCheckbox from "@/components/inputs/InputCheckbox";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";

export default {
  data() {
    return {
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      message: '',
      typeAlert: 'success',
      //-----------------------------
      roles: [],
      idRole: '',
      permissions: [],      
      patientList: '',
      patientCreate: '',
      patientEdit: '',
      diaryList: '',
      diaryCreate: '',
      diaryEdit: '',
      paymentList: '',
      paymentCreate: '',
      procedureList: '',
      procedureCreate: '',
      formList: '',
    };
  },
  components: {
    InputSelect,
    InputCheckbox,
    ButtonSubmit,
  },
  created(){
    this.getRoles()
  },
  methods: {
    addPermissions() {
      this.permissions = [1, 2, 4, 54, 55, 65,72, 73, 74]
      if(this.patientList=="accepted"){this.permissions.push(5)}
      if(this.patientCreate=="accepted"){this.permissions.push(6, 7)}
      if(this.patientEdit=="accepted"){this.permissions.push(8, 10)}
      if(this.diaryList=="accepted"){this.permissions.push(13)}
      if(this.diaryCreate=="accepted"){this.permissions.push(14, 12, 15, 55)}
      if(this.diaryEdit=="accepted"){this.permissions.push(17, 18)}
      if(this.paymentList=="accepted"){this.permissions.push(27)}
      if(this.paymentCreate=="accepted"){this.permissions.push(28, 29, 35)}
      if(this.procedureList=="accepted"){
        this.permissions.push(12, 13, 14,21,22, 63, 64, 65); 
      }
      if(this.procedureCreate=="accepted"){this.permissions.push(66, 67, 12, 63)}
      
      Role.addPermissions(this.permissions, this.idRole)
      .then((res) => {
        this.message = res.message
        this.showAlert();
      })
      .catch((err) => console.log(err));
    },
    getRoles(){
      Role.list()
      .then((res) => {
        this.roles = res.data
      })
      .catch((err) => console.log(err));
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>
<style>
.row-info p{
  color: #00A098;
  font-size: 1.1em;
  font-weight: bold;
}
.permission-row{
  background-color: #E6F6F5;
}
</style>