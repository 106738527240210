import env from '@/environment/env'

const create = async (name) => {
    try{
        let body = {'name':name, 'guard_name': 'api'}
        
        const res = await fetch(env.prod+'role', {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer "+localStorage.getItem('key')
            },
            body: JSON.stringify(body),
          }
        );
        const data = await res.json();
        return data;
    }catch(error){
        return error
    }
}

const addPermissions = async (permissions, idRole) => {
    try {
        let body = {'idrole':idRole, 'permission': permissions}
        
        const res = await fetch(env.prod+'role/syncPermissions', {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer "+localStorage.getItem('key')
            },
            body: JSON.stringify(body),
          }
        );
        const data = await res.json();
        return data;
    } catch (error) {
        return error
    }
}

const list = async ()=>{
    try {
        const res = await fetch(env.prod+'role',{
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            }
        })
        const data = await res.json()
        return data
    } catch (error) {
        console.log(error)
    }
}


export default{
    create, addPermissions, list
}