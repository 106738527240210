<template>
  <b-container fluid>
    <b-row>
      <b-col cols="2">
        <side-menu @swap="currentComponent = $event"></side-menu>
      </b-col>
      <b-col cols="10" class="p-0">
        <div :is="currentComponent"></div>
      </b-col>
    </b-row>
    <button-logout></button-logout>
  </b-container>
</template>

<script>
import ListForms from './ListForms'
import ListPatients from "../../views/admin/patients/ListPatients.vue";
import CreatePatient from "../../views/admin/patients/CreatePatient.vue";
import ListDiaries from "../../views/admin/diaries/ListDiaries.vue";
import CreateDiary from "../../views/admin/diaries/CreateDiary.vue";
import ListPayments from "../../views/admin/payments/ListPayments.vue";
import CreatePayment from "../../views/admin/payments/CreatePayment.vue";
import ListProcedures from "../../views/admin/procedures/ListProcedures.vue";
import CreateProcedure from "../../views/admin/procedures/CreateProcedure.vue";
import EditUser from "../../views/admin/users/EditUser.vue";
import ListUsers from "../../views/admin/users/ListUsers.vue";
import CreateRol from "../../views/admin/users/CreateRol.vue";
import CreateUser from "../../views/admin/users/CreateUser.vue";
import PermissionsUser from "../../views/admin/users/PermissionsUser.vue";

import ButtonLogout from '@/components/buttons/ButtonLogout';
import SideMenu from '@/components/admin/SideMenu'

export default {
  data() {
    return {
      currentComponent: ListForms,
      isHover: false
    };
  },
  components: {
    ButtonLogout,
    SideMenu,
    ListForms,
    ListPatients,
    CreatePatient,
    ListDiaries,
    CreateDiary,
    ListPayments,
    CreatePayment,
    ListProcedures,
    CreateProcedure,
    ListUsers,
    CreateRol,
    CreateUser,
    PermissionsUser,
    EditUser
  },  
};
</script>

<style>
.button-menu{
  width: 100% !important;
  background-color: #E6F6F5 !important;
  color: #00A098;
  border: none;
  border-radius: 0;
  height: 50px;
  font-weight: bold;
  text-align: left;
  display: flex;
  padding: 1em;
  padding-left: 2em;
}
.button-menu img{
  margin: auto;
  margin-right: 1em;
}
.button-menu:hover,.button-menu:focus{
  background-color: #00A098 !important;
  color: #E6F6F5;
}
.collapse button{
  width: 100% !important;
  background-color: #E6F6F5 !important;
  color: #00A098;
  border: none;
  height: 30px;
}
.collapse button:hover,.collapse button:focus{
  background-color: #00A098 !important;
  color: #E6F6F5;
}
.admin-body::before, .admin-body::after{
  top: 20vh;
  content: '';
  min-width: 2vw;
  min-height: 80vh;
  position: absolute;
  background-color: #00a09890;
  bottom: 0px;
}
.admin-body::before{
  left: 0px;
}
.admin-body::after{
  right: 0px;
}
.admin-body{
  padding: 0;
  padding-bottom: 20px;
  height: auto;
  min-height: 100vh;
}
.header-admin{
  background-color: #E6F6F5;
  padding-bottom: 3em;
  padding-top: 3em;
  height: 20vh;
}
.header-admin h1{
  color: #9C182B;
  font-weight: bold;
}
.header-admin p{
  font-size: 1.1em;
}
#sidebar-1{
  background-color: #fff;
}
.b-sidebar{
  width: 16.5%;
}
.b-table-sticky-header {
  max-height: 800px;
}
</style>
