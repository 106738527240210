<template>
  <b-card
    :img-src=country.image
    img-alt="Image"
    img-top
    style="max-width: 20rem"
    tag="article"
    class="mb-2 "
  >
    <b-card-title>{{country.title}}</b-card-title>
    <b-card-body >
        <hr class="line">
        <button-get-into v-b-modal="country.id + 'country'"
            :btnHeight="'30px'"
            :btnWidth="'150px'"
            :text="btnText"
            :btnBackgroundColor="'#00A098'"
            :btnFontSize="'.95em'"
            :onTap="()=>{}"
            >
        </button-get-into>
        <b-modal size="lg" class="country-modal" :id="country.id + 'country'" hide-footer headerClass='border-bottom-0' >
            <b-container fluid>
                <b-row align-h="between" class="justify-content-center text-center">
                    <b-col>
                        <h3 class="mb-4">{{country.title}}</h3>
                    </b-col>
                </b-row>
                <b-row align-h="between" class="justify-content-center text-center">
                    <b-col v-for="i in country.delegates" :key="i.id">
                        <delegate-card class="delegate-card" :country="country" :delegate="i"></delegate-card>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
    </b-card-body>
  </b-card>
</template>

<script>
import ButtonGetInto from '../buttons/ButtonGetInto.vue'
import DelegateCard from "@/components/cards/DelegateCard";

export default {
    props:{
        country:{
            type: Object,
            default: null
        },
        btnText:{
            type:String,
            default:""
        }
    },
    components:{
        'button-get-into': ButtonGetInto,
        DelegateCard
    }
}
</script>

<style>
.delegate-body{
    display: flex;
}
#country article{
    display: block;
    border: none;
    border-radius: 40px;
    width: auto;
    margin: auto;
    padding: 0.5em;
    background-color: transparent;
    min-width: 80%;
}
#country article p{
    text-align: center;
    font-weight: 400;
}
#country article .card-title{
    font-size: large;
    color: #00A098 !important;
    font-weight: 700;
    margin-bottom: 4px;
}
#country .card-img-top{
    border-radius: 50%;
    width: 13vw;
    height: 13vw;
    box-shadow: #ccc 0px 0px 10px 3px;
}
#country .card-body img{
    border-radius: none;
    max-height: 100px;
    max-width: 300px;
}
#country article .price{
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 0;
}
#country article .price span{
    font-size: 0.7em;
}
#country hr{
    background-color: #00A09840;
    height: 6px;
    margin-bottom: 5px;
    margin-top: 5px;
    border: none;
}
#country article .subtitle{
    margin-bottom: 10px;
    font-size: .8em;
    color: #00A098;
    font-weight: bold;
}
#country article table{ 
    width: 100%;
}
#country article th{
    text-align: end;
    font-size: .8em;
    line-height: 1.1em;
}
#country article .city{
    text-align: start;

}
.modal-content {
    text-align: center;
}
.country-modal-line{
    background-color: #00A09870;
    height: 4px;
    border: none;
    width: 60%;
}
.country-modal-title{
    color: #00A098;
    font-size: 2.5em;
    font-weight: bold;
} 
.country-modal-description{
    font-size: 1.2em;
}
.country-modal-table{
    font-size: 1.1em;
    margin: auto;
}
.modal-header .close {
    margin: 0;
    font-size: 2em !important;
}
</style>