import env from '@/environment/env'

const login = async (email, password) => {
  try{
    var body = {'email':email, 'password':password}
    
    const res = await fetch(env.prod+'auth/login', {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
      );
    const data = await res.json();
    return data;
  }catch(err){
      return err
  }
}

const logout = async () => {
  try {
    const res = await fetch(env.prod+'auth/logout', {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer "+localStorage.getItem('key')
        }
      });
    const data = await res.json();
    return data;
  } catch (error) {
    return error
  }
}

const getUserData = async () => {
  try {
    const res = await fetch(env.prod+'auth/user', {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer "+localStorage.getItem('key')
        }
      });
    const data = await res.json();
    return data;
  } catch (error) {
    return error
  }
}

const getUserPermissions = async () => {
  try {
    const res = await fetch(env.prod+'usuario/permissions', {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer "+localStorage.getItem('key')
        }
      });
    const data = await res.json();
    return data;
  } catch (error) {
    return error
  }
}


export default{
    login, logout, getUserData, getUserPermissions
}