<template>
  <div class="admin-body">
    <div class="header-admin">
      <h1>Reporte de Formulario de contacto</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Inventore
        autem non minima hic laboriosam dicta?
      </p>
    </div>
    <b-container >
      <b-row>
        <b-col cols="4">
          <input-text 
            class="mt-3"
            :minimum="0"
            :txtBorder="'none'"
            :placeholder="'Buscar...'"
            :txtBackground="'transparent'"
            :txtShadow="'-3px 3px 0px 2px #00a098'"
            @text="filter = $event"
          ></input-text>
        </b-col>
      </b-row>
      
      <b-row class="pt-3 justify-content-center">
        <b-col cols="6">
          <b-alert
            :show="dismissCountDown"
            dismissible
            :variant="typeAlert"
            @dismissed="dismissCountDown = 0"
            @dismiss-count-down="countDownChanged"
          >
            {{ message }} {{ dismissCountDown }}
          </b-alert>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table
            class="mt-3"
            hover
            bordered
            responsive
            sticky-header
            :items="listForm"
            :fields="fields"
            :filter="filter"
          >
            <template #cell(estado)="row">
              <b-button
                variant="danger"
                v-if="row.item.estado == 'NO'"
                size="sm"
                @click="attend(row.item.idformulario_contacto)"
                class="mr-1">
                No
              </b-button>
              <b-button
                variant="success"
                @click="attend(row.item.idformulario_contacto)"
                v-else
                size="sm"
                class="mr-1"
              >
                Si
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ContactForm from "@/services/contactform.service";
import InputText from "@/components/inputs/InputText.vue";

export default {
  components: {
    InputText,
  },
  data() {
    return {
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      message: "",
      typeAlert: "success",
      //list tables
      fields: [
        {
          key: "created_at",
          label: "Fecha",
          formatter: (value) => {
            if (value) {
              return value.slice(0, 10);
            }
            return value
          },
        },
        "nombres",
        "apellidos",
        "email",
        { key: "celular", label: "teléfono" },
        { key: "interes", label: "interés" },
        { key: "estado", label: "Atendido" },
      ],
      filter: "",
      listForm: [],
    };
  },
  methods: {
    listForms() {
      ContactForm.list()
      .then((res) => {
        if (res.success) {
          this.listForm = res.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    attend(id) {
      ContactForm.attend(id)
      .then((res) => {
        if (res.success) {
          this.message = res.message
          this.showAlert();
          this.listForms()          
        }
      })
      .catch((err) => {
        console.log(err);
      });      
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
  mounted() {
    this.listForms();
  },
};
</script>

<style>
</style>