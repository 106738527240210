<template>
  <div class="payment-page">
    <nav-bar></nav-bar>
    <b-container class="pt-lg-5">
      <b-row class="mt-lg-5 pt-5">
          <b-col>
              <h1>Pagos</h1>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing.</p>
          </b-col>
      </b-row>
      <b-form @submit.stop.prevent="getPatient">
        <b-row class="mt-lg-5 justify-content-center">
          <b-col cols="lg-4" class="col-md-12">
            <input-select
              :placeholder="'Tipo de documento'"
              :options="documents"
              :txtBackground="'white'"
              @value="idtipodoc = $event"
            ></input-select>
          </b-col>
          <b-col cols="lg-4 justify-content-center" class="col-md-12">
            <input-text
              :placeholder="'Número de documento'"
              :txtBorder="'none'"
              :txtBackground="'white'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="numedoc = $event"
            ></input-text>
          </b-col>
        </b-row>
        <b-row class="mt-3 justify-content-center">
          <b-col>
            <button-submit
              :btnBackgroundColor="'#00a098'"
              :text="'Consultar'"
              :btnWidth="'300px'"
              :btnFontSize="'1.6em'"
            ></button-submit>
          </b-col>
        </b-row>
      </b-form>

      <b-row class="mt-5 justify-content-center row-info">
        <b-col cols="lg-3" class="col-sm-6">
          <p>{{ patientName }}</p>
        </b-col>
        <b-col cols="0" class="sm-none"><p>|</p></b-col>
        <b-col cols="lg-2" class="col-sm-5">
          <p>{{ patientDocument }}</p>
        </b-col>
        <b-col cols="0" class="sm-none"><p>|</p></b-col>
        <b-col cols="lg-3" class="col-sm-6">
          <p>{{ patientCel }}</p>
        </b-col>
        <b-col cols="0" class="sm-none"><p>|</p></b-col>
        <b-col cols="lg-2" class="col-sm-5">
          <p>{{ patientMail }}</p>
        </b-col>
      </b-row>

       <b-row>
        <b-col>
          <b-table
            class="mt-4"
            hover
            bordered
            responsive
            sticky-header
            :items="payments"
            :fields="fields"
            :filter="filter"
          >
            <template #cell(actions)="row">
              <a target="_blank" :href="row.item.urlpago">
                <b-button
                  variant="success"
                  size="sm"
                  @click="info(row.item)"
                  class="mr-1">
                  Pagar
                </b-button>         
              </a>
              <b-button
                id="tooltip-button-1"
                variant="success"
                size="sm"
                @click="copyPaymentLink(row.item.urlpago)"
                class="mr-1">
                <img src="../assets/copy.png" width="24" height="24" alt="">
                <!-- <b-icon icon="link45deg" variant="light"></b-icon> -->
              </b-button>
              <b-tooltip triggers="click" delay="50" target="tooltip-button-1" placement="top">
                <strong>Enlace de pago</strong> copiado!
              </b-tooltip>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import PaymentService from "@/services/payments.service";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import NavBar from "@/views/landing/NavBar.vue";
export default {
  components: {
    InputText,
    InputSelect,
    ButtonSubmit,
    NavBar,
  },
  created(){
    
    this.getPaymentStatesMethod()
    this.getTypesDocumentMethod()
  },
  data(){
    return{
      numedoc: "",
      idtipodoc: "",
      patientMail: "Correo",
      patientDocument: "Documento",
      patientCel: "Número celular",
      patientName: "Nombre paciente",
      fields: [
        {key:"procedimientos", label: "Procedimiento"},
        { key: "idestadopago", label: "Estado",
          formatter: (value) => {
            return this.states.filter(function (element){
                return element.idestado_pago == value
            })[0].nombre_estado_pago
          },
        },
        {
          key: "fechalimite",
          label: "Fecha Limite",          
        },
        { key: "actions", label: "Acciones" },
      ],
      filter: "",
      show: false,
      documents: [],
      states: [],
      payments: [],
    }
  },
  methods: {
    getPatient() {
      PaymentService.getPatientPayments(this.idtipodoc, this.numedoc)
      .then((res) => {
        this.patient = res.data.paciente[0];
        this.patientName = this.patient.pnombre+' '+this.patient.papellido;
        this.patientMail = this.patient.email;
        this.patientCel = this.patient.celular;
        this.patientDocument = this.patient.numedoc;
        this.payments = res.data.pagos;
      })
      .catch((err) => console.log(err));
    },
    copyPaymentLink(text){
      this.show = true
      navigator.clipboard.writeText(text);
    },
    getPaymentStatesMethod(){
      PaymentService.getPaymentStates()
      .then((res) => {
        this.states = res.data
      })
      .catch((err) => console.log(err));
    },
    getTypesDocumentMethod(){
      PaymentService.getTypesDocument()
      .then((res) => {        
        this.documents = res.data
      })
      .catch((err) => console.log(err));

    }
  }
};
</script>

<style>
.payment-page{
  background-image: url("../assets/fondo-pagos.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: top;
  height: 92vh;
  margin-top: 8vh;
}
.payment-page td{
  background-color: #fff;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .payment-page{
    background-size: cover;
    height: 92vh;
    margin-top: 8vh;
  } 
  .sm-none{
    display: none;
  }
}
</style>