<template>
  <div class="admin-body">
    <div class="header-admin">
      <h1>Lista de usuarios</h1>
      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Inventore autem non minima hic laboriosam dicta?</p>
    </div>
    <b-container>
      <b-row>
        <b-col class="mt-5">
          <b-table
            class="mt-3"
            hover
            bordered
            responsive
            sticky-header
            :items="listUser"
            :fields="fields"
            :filter="filter"
          >
                <!-- @click="info(row.item)" -->
            <template #cell(estado)="row">
              <b-button
                variant="success"
                size="sm"
                @click="info(row.item)" 
                class="mr-1">
                Editar
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
    <b-modal size="xl" ref="user-modal" class="user-modal" hide-footer headerClass='border-bottom-0' >
      <edit-user :propUser="currentUser"></edit-user>
    </b-modal>
  </div>
</template>

<script>
import UserService from "@/services/user.service";
import EditUser from "./EditUser.vue"

export default {
  components:{
    EditUser
  },
  data(){
    return{
      currentUser: null,
      fields: [
        {
          key: "primer_nombre",
          label:"Nombre",
        },
        {
          key: "roles[0].name",
          label:"Rol",
        },
        {
          key: "idtipodoc",
          label:"Tipo Documento",
          formatter: (value) => {
            return this.documents.filter((el)=>{
              return el.idtipo_documento == value
            })[0].nombre
          },
        },
        {
          key: "numedoc",
          label: "Número",
        },
        {
          key: "email",
          label: "Email",
        },
        { key: "estado", label: "Estado" },
      ],
      filter: "",
      listUser: [],
    }
  },
  props: {
    component: {type: Function}    
  },
  methods: {
    listUsers() {
      UserService.list()
        .then((res) => {
          if (res.success) {
            this.listUser = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    info(item){
      this.currentUser = item
      this.$refs['user-modal'].show()
    }
  },
  created(){
    this.listUsers()
    this.documents = JSON.parse(localStorage.resources).tipo_documentos
  }

}
</script>

<style>
 
</style>