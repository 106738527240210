<template>
  <div class="admin-body">
    <div class="header-admin">
      <h1>Registro de Pagos</h1>
      <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
    </div>
    <b-container>
      <b-form @submit.stop.prevent="findPatient">
        <b-row class="mt-4 justify-content-center">
          <b-col cols="4">
            <input-select
              :placeholder="'Tipo de documento'"
              :options="documents"
              @value="idtipodoc = $event"
            ></input-select>
          </b-col>
          <b-col cols="4 justify-content-center">
            <input-text
              :placeholder="'Número de documento'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="numedoc = $event"
            ></input-text>
          </b-col>
        </b-row>
        <b-row class="mt-3 justify-content-center">
          <b-col>
            <button-submit
              :btnBackgroundColor="'#00a098'"
              :text="'Consultar'"
              :btnWidth="'300px'"
              :btnFontSize="'1.6em'"
            ></button-submit>
          </b-col>
        </b-row>
      </b-form>

      <b-row class="mt-5 justify-content-center row-info">
        <b-col cols="3"><p>{{patientName}}</p></b-col>
        <b-col cols="0"><p>|</p></b-col>
        <b-col cols="2"><p>{{patientDocument}}</p></b-col>
        <b-col cols="0"><p>|</p></b-col>
        <b-col cols="3"><p>{{patientCel}}</p></b-col>
        <b-col cols="0"><p>|</p></b-col>
        <b-col cols="2"><p>{{patientMail}}</p></b-col>
      </b-row>

      <b-form @submit.stop.prevent="createPayment">
        <b-row class="mt-5 justify-content-center row-info">
          <b-col cols="4">
            <label class="label-form-procedure">Procedimiento</label>
            <input-text
              :placeholder="'Procedimiento...'"
              :txtBorder="'1px solid #00A098'"
              :txtBackground="'transparent'"
              @text="procedure = $event"
            ></input-text>
          </b-col>
          <b-col cols="2">
            <label class="label-form-procedure">Valor $</label>
            <input-text
              :type="'text'"
              :placeholder="'###'"
              :txtBorder="'1px solid #00A098'"
              :txtBackground="'transparent'"
              @text="value = $event"
            ></input-text>
          </b-col>
          <b-col cols="2">
            <label class="label-form-procedure">Fecha Limite</label>
            <input-calendar-selector
              :placeholder="'Fecha'"
              :txtBorder="'1px solid #00A098'"
              :txtBackground="'transparent'"
              :txtShadow="'none'"
              @value="dueDate = $event"
            ></input-calendar-selector>
          </b-col>
          <b-col cols="3">
            <label class="label-form-procedure">Url</label>
            <input-text
              :placeholder="'www.urldepago.com'"
              :txtBorder="'1px solid #00A098'"
              :txtBackground="'transparent'"
              @text="url = $event"
            ></input-text>
          </b-col>
          <b-col cols="1">
            <label class="mb-3 label-form-procedure-t">Acción</label>            
            <button-submit
              class="pt-2"
              :btnBackgroundColor="'#00a098'"
              :text="'Agregar'"
              :btnWidth="'200px'"
              :btnFontSize="'1.5em'"
            ></button-submit>
          </b-col>
        </b-row>
      </b-form>
      <b-row class="pt-3 justify-content-center">
        <b-col cols="6">
          <b-alert
            :show="dismissCountDown"
            dismissible
            :variant="typeAlert"
            @dismissed="dismissCountDown = 0"
            @dismiss-count-down="countDownChanged"
          >
            {{ message }} {{ dismissCountDown }}
          </b-alert>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import Diary from "@/services/diary.service";
import Payment from "@/services/payments.service";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import InputCalendarSelector from "@/components/inputs/InputCalendarSelector";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";

export default {
  name: "create-payment",
  data() {
    return {
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      message: "",
      typeAlert: "success",

      //patient data
      idtipodoc: "",      
      procedure: "",
      value: "",
      dueDate: "",
      url: "",
      
      //patient data
      patient: null,
      patientMail: "Correo",
      patientDocument: "Documento",
      patientCel: "Número celular",
      patientName: "Nombre paciente",
      documents: [],
    };
  },
  components: {
    InputText,
    InputSelect,
    InputCalendarSelector,
    ButtonSubmit,
  },
  created() {
    this.documents = JSON.parse(localStorage.resources).tipo_documentos;
  },
  methods: {
    findPatient() {
      Diary.find(      
        this.idtipodoc, this.numedoc
      )
      .then((res) => {
        this.patient = res.data.paciente[0]
        this.patientName  = this.patient.pnombre
        this.patientMail = this.patient.email
        this.patientCel = this.patient.celular
        this.patientDocument = this.patient.numedoc
      })
      .catch((err) => console.log(err));
    },
    createPayment() {
      this.dueDate = moment(this.dueDate).format("YYYY/MM/DD");
      Payment.create(
        this.procedure,
        this.patient.idpaciente,
        this.url,
        this.value,
        this.dueDate,
      )
      .then((res) => {
        this.message = res.message
        this.showAlert();
      })
      .catch((err) => console.log(err));
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>

<style scoped>
.label-form-procedure {
  color: #9c182b;
  font-size: 1.3em;
  font-weight: bold;
}
.label-form-procedure-t {
  color: transparent;
}
</style>