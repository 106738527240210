<template>
  <section id="package">
    <b-container fluid class="pt-4 pb-2 mb-3 mr-0 ml-0">
      <b-row >
        <b-col class="justify-content-center">
          <h2 class="mt-5">{{packages[0].section_title}}</h2>
        </b-col>
      </b-row>
      <b-row class="justify-content-center mt-2">
        <b-col cols="10">
          <carousel
            :centerMode="true"
            :autoplayTimeout="5000"
            :perPageCustom="[
              [350, 1],
              [380, 1],
              [480, 1],
              [768, 2],
              [1080, 3],
            ]"
            :loop="true"
            :autoplay="true"
            :navigationEnabled="true"
            navigationNextLabel='<img src="" >'
            navigationPrevLabel='<img src="" >'
          >
            <slide v-for="i in packages" :key="i.id">
              <package-card :packageDt="i" :btnText="packages[0].see_more"></package-card> 
            </slide>
          </carousel>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import PackageCard from "@/components/cards/PackageCard.vue";
import { Carousel, Slide } from "vue-carousel";

export default {
  props: {
    packages: {
      type: Array,
      default: null,
    },
  },
  components: {
    PackageCard,
    Carousel,
    Slide,
  },
};
</script>

<style>
#package {
  height: 80vh;
}
#package h2 {
  color: #00a098;
  font-size: 3.5em; 
  font-weight: bold;
}
#package p {
  font-size: 1em;
}
#package ul {
  padding-top: 40px;
  padding-bottom: 40px;
}
#package .btn-rigth {
  right: -3vw;
}
#package .btn-left {
  left: -3vw;
}
#package .VueCarousel-navigation-button::before {
  content: "";
  position: absolute;
  top: 8px;
  height: 50px;
  width: 40px;
}

#package .VueCarousel-navigation-next::before {
  background: url('/assets/arrow-r-l.png');
  background-repeat: no-repeat;
  background-size: contain;
  right: -30px;
}

#package .VueCarousel-navigation-prev::before {
  background: url('/assets/arrow-l-l.png');
  background-repeat: no-repeat;
  background-size: contain;
  left: 20px;
}

@media screen and (max-width: 1400px) and (orientation: landscape) { 
  #package {
    height: 90vh;
  }
  #package h2{
    font-size: 3em;
    margin-top: 0em !important;
  }
  #package article .price{
    font-size: .8em;
  }
  #package .VueCarousel-navigation-prev::before {
    left: 10px;
  }
}

@media screen and (max-width: 600px) and (orientation: portrait) { 
  #package .VueCarousel-navigation-prev::before {
    left: -20px;
  }
  #package {
    height: 70vh;
  }
}
@media screen and (max-width: 440px) and (orientation: portrait) { 
  #package .card-img-top{
    width: 34vw;
    height: 34vw;
  }

}
@media screen and (max-width: 360px) and (orientation: portrait) { 
  #package .VueCarousel-navigation-prev::before {
    left: -20px;
  }
  #package {
    height: 80vh;
  }
    #package h2 {
    font-size: 2.5em; 
  }
}
</style>