<template>
  <section id="ips">
    <b-container fluid class="pb-2 mt-5 mb-3">
      <b-row>
        <b-col class="justify-content-center">
          <h1>{{infoIps[0].section_title}}</h1>
        </b-col>
      </b-row>
      <b-row class="justify-content-center mt-2">
        <b-col cols="lg-11" class="col-md-12">
          <carousel
            :autoplayTimeout="5000"
            :perPageCustom="[
              [340, 1],
              [380, 1],
              [480, 1],
              [768, 2],
              [1080, 3],
              [1480, 4],
            ]"
            :loop="true"
            :autoplay="true"
            :navigationEnabled="true"
            navigationNextLabel='<img src="" >'
            navigationPrevLabel='<img src="" >'
          >
            <slide v-for="i in infoIps" :key="i.id">
              <ips-card :ips="i"></ips-card>
            </slide>
          </carousel>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import IpsCard from "@/components/cards/IpsCard.vue";

export default {
  props: {
    infoIps: {
      type: Array,
      default: null,
    },
  },
  components: {
    IpsCard,
    Carousel,
    Slide,
  },
};
</script>

<style>
#ips h1 {
  color: #00a098;
  font-size: 3.5em;
  font-weight: bold;
}
#ips p {
  font-size: 0.6em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-size: 0.7em;
}
#ips .VueCarousel-wrapper {
  padding-top: 1em;
  padding-left: 2em;
}
#ips .VueCarousel-navigation-next {
  right: -1.5vw;
}
#ips .VueCarousel-navigation-prev {
  left: -1.5vw;
}
#ips .VueCarousel-navigation-button::before {
  content: "";
  position: absolute;
  top: 8px;
  height: 50px;
  width: 40px;
}

#ips .VueCarousel-navigation-next::before {
  background: url("/assets/arrow-r-d.png");
  background-repeat: no-repeat;
  background-size: contain;
  right: -30px;
}

#ips .VueCarousel-navigation-prev::before {
  background: url("/assets/arrow-l-d.png");
  background-repeat: no-repeat;
  background-size: contain;
  left: -20px;
}

@media screen and (max-width: 1400px) and (orientation: landscape) {
  #ips h1 {
    font-size: 2.5em;
  }
  #ips h4 {
    font-size: 0.8em;
  }
  #ips p {
    font-size: 0.6em;
  }
}
@media screen and (max-width: 1400px) and (orientation: portrait) {
  #ips h1 {
    font-size: 2.5em;
  }
  #ips .VueCarousel-navigation-prev::before {
    left: 10px;
  }
  #ips .VueCarousel-navigation-next::before {
    right: -10px;
  }
  #ips .VueCarousel-wrapper {
    padding-left: 2.5em;
  }
}
@media screen and (max-width: 1400px) and (orientation: portrait) {
  #ips .VueCarousel-wrapper {
    padding-left: 1.5em;
  }
}
</style>