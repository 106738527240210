<template>
  <section id="info">
    <img v-if="show" src="../../assets/info-h.jpg" alt="">
    <img v-else src="../../assets/info-v.jpg" alt="">
  </section>
</template>

<script>
export default {
  data() {
    return {
      show: true,
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      if(window.innerWidth <= 600){
        this.show = false
      }
      else{
        this.show = true
      }
    }
  }
}
</script>

<style>
#info{
  display: flex;
}
#info img{
  width: 100vw;
  height: auto;
  max-width: 100vw;
}
@media screen and (max-width: 1400px) and (orientation: landscape) {
  #info img{
    max-width: 1400px;
  }
  
}

</style>