<template>
  <div class="admin-body">
    <div class="header-admin">
      <h1>Listado de pagos</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Inventore
        autem non minima hic laboriosam dicta?
      </p>
    </div>
    <b-container>
      <b-form @submit.stop.prevent="getPatient">
        <b-row class="mt-4 justify-content-center">
          <b-col cols="4">
            <input-select
              :placeholder="'Tipo de documento'"
              :options="documents"
              @value="idtipodoc = $event"
            ></input-select>
          </b-col>
          <b-col cols="4 justify-content-center">
            <input-text
              :placeholder="'Número de documento'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="numedoc = $event"
            ></input-text>
          </b-col>
        </b-row>
        <b-row class="mt-3 justify-content-center">
          <b-col>
            <button-submit
              :btnBackgroundColor="'#00a098'"
              :text="'Consultar'"
              :btnWidth="'300px'"
              :btnFontSize="'1.6em'"
            ></button-submit>
          </b-col>
        </b-row>
      </b-form>

      <b-row class="mt-5 justify-content-center row-info">
        <b-col cols="3"
          ><p>{{ patientName }}</p></b-col
        >
        <b-col cols="0"><p>|</p></b-col>
        <b-col cols="2"
          ><p>{{ patientDocument }}</p></b-col
        >
        <b-col cols="0"><p>|</p></b-col>
        <b-col cols="3"
          ><p>{{ patientCel }}</p></b-col
        >
        <b-col cols="0"><p>|</p></b-col>
        <b-col cols="2"
          ><p>{{ patientMail }}</p></b-col
        >
      </b-row>

      <b-row class="pt-3 justify-content-center">
        <b-col cols="6">
          <b-alert
            :show="dismissCountDown"
            dismissible
            :variant="typeAlert"
            @dismissed="dismissCountDown = 0"
            @dismiss-count-down="countDownChanged"
          >
            {{ message }} {{ dismissCountDown }}
          </b-alert>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table
            class="mt-3"
            hover
            bordered
            responsive
            sticky-header
            :items="payments"
            :fields="fields"
            :filter="filter"
          >
            <template #cell(url)="row">
              <a target="_blank" :href="row.item.urlpago">{{row.item.urlpago}}</a>
            </template>
            <template #cell(estado)="row">
              <input-select
              @onPayment="updatePayment(row)"
              :txtShadow="'none'"
              :placeholder="paymentStates.filter(function (el){  
                  return el.idestado_pago == row.item.idestadopago 
                })[0].nombre_estado_pago"
                :options="paymentStates"
                @value="row.item = $event"
              ></input-select>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import PaymentService from "@/services/payments.service";
import InputText from "@/components/inputs/InputText.vue";
import InputSelect from "@/components/inputs/InputSelect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";

export default {
  components: {
    InputText,
    InputSelect,
    ButtonSubmit,
  },
  data() {
    return {
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      message: "",
      typeAlert: "success",
      //patient data
      numedoc: "",
      idtipodoc: "",
      patientMail: "Correo",
      patientDocument: "Documento",
      patientCel: "Número celular",
      patientName: "Nombre paciente",
      //table fields
      fields: [
        {key:"url", label:"Url"},
        {
          key: "fechalimite",
          label: "Fecha Limite",
        },
        { key: "estado", label: "Estado" },
      ],
      filter: "",
      payments: [],
      documents: [],
      paymentStates: []
    };
  },
  methods: {
    getPatient() {
      PaymentService.getPatientPayments(this.idtipodoc, this.numedoc)
      .then((res) => {
        this.patient = res.data.paciente[0];
        this.patientName = this.patient.pnombre+' '+this.patient.papellido;
        this.patientMail = this.patient.email;
        this.patientCel = this.patient.celular;
        this.patientDocument = this.patient.numedoc;
        this.payments = res.data.pagos;
      })
      .catch((err) => console.log(err));
    },
    updatePayment(item){
      this.payments[item.index].fechalimite = moment(this.payments[item.index].fechalimite).format("YYYY/MM/DD");
      PaymentService.update(this.payments[item.index], item.item)
      .then(res => {
        this.message = res.message;
        this.showAlert();
      })
      .catch((err) => console.log(err));
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
  mounted() {
    this.documents = JSON.parse(localStorage.resources).tipo_documentos;
    this.paymentStates = JSON.parse(localStorage.resources).estados_pagos;
  },
};
</script>