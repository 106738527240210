<template>
  <b-card
    :img-src="sponsorsImage"
    img-alt="Image"
    img-top
    tag="article"
    style="max-width: 20rem"
  >
  </b-card>
</template>

<script>
export default {
  props: {
    sponsorsImage: {
      type: String,
      default: "https://picsum.photos/600/300/?image=25",
    },
  },
};
</script>

<style>
#sponsors article {
  box-shadow: #efefef 0px 0px 10px 6px;
  border-radius: 40px;
  align-items: center;
  min-width: 80%;
  width: 80%;
  padding: 0;
  margin: auto;
}
#sponsors article p {
  text-align: justify;
  font-weight: 400;
}
#sponsors article h4 {
  color: #00a098;
  font-weight: 600;
}
#sponsors .card img {
  width: 8vw;
  height: 8vw;
  min-height: 210px;
  min-width: 210px;
  border-radius: 20px;
}
#sponsors .card-body {
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 600px) and (orientation: portrait) {
  #sponsors .card img {
    width: 120px;
    height: auto;
  }
  #sponsors article {
    width: auto;
    padding: 0.3em .2em;
    margin: 0 1em;
  }
  #sponsors .card img {
    min-height: unset;
    min-width: 200px;
  }
  
}
@media screen and (max-width: 360px) and (orientation: portrait) { 
  #sponsors article {
    margin: 0 auto;
  }
}
</style>