import env from '@/environment/env'

const getLang = ()=>{
    return localStorage.getItem('lang') || env.locale;
}

const setLang = (newLang)=>{
    localStorage.setItem('lang', newLang);
}

export{
    getLang, setLang
}