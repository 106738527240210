<template>
  <section id="professionals" ref="professionals">
    <b-container fluid class="pb-2 mb-3 mr-0 ml-0">
      <b-row  class="professionals-title">
        <b-col class="justify-content-center">
          <h2>{{professionals[0].section_title}}</h2>
        </b-col>
      </b-row>
      <b-row class="justify-content-center mt-2">
        <b-col class="col-10 ">
          <carousel :autoplayTimeout="5000" :perPageCustom="[[350, 1], [380, 1],[480, 1], [768, 2], [1080, 3], [1480, 4]]" :loop="true" :autoplay="true" :navigationEnabled="true"
            navigationNextLabel='<img src="" >'
            navigationPrevLabel='<img src="" >'
            >
            <slide v-for="i in professionals" :key="i.id">
              <professional-card :professional="i"></professional-card>
            </slide>
          </carousel>
        </b-col> 
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
// import { professionals } from '@/services/data.service'
import ProfessionalCard from '@/components/cards/ProfessionalCard.vue'

export default {
  props: {
    professionals: {
      type: Array,
      default: null,
    },
  },
  // data(){
  //   return {
  //     infoProfessionals: professionals
  //   }
  // },
  components: {
    ProfessionalCard,
    Carousel,
    Slide
  },
}
</script>

<style>
#professionals {
  background-image: url('/assets/fondo-profesinales-salud.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 90vh;
}
#professionals h2 {
  color: #00a098;
  font-size: 3.5em;
  font-weight: bold;
}
#professionals p {
  font-size: .65em;
}
#professionals ul{
  padding-top: 40px;
  padding-bottom: 40px;
}
#professionals .VueCarousel-navigation-next{
  right: -1.5vw;
}
#professionals .VueCarousel-navigation-prev{
  left: -1.5vw;
}
#professionals .VueCarousel-navigation-button::before {
  content: "";
  position: absolute;
  top: 8px;
  height: 50px;
  width: 40px;
}

#professionals .VueCarousel-navigation-next::before {
  background: url('/assets/arrow-r-d.png');
  background-repeat: no-repeat;
  background-size: contain;
  right: -30px;
}

#professionals .VueCarousel-navigation-prev::before {
  background: url('/assets/arrow-l-d.png');
  background-repeat: no-repeat;
  background-size: contain;
  left: -30px;
}

#professionals .container-fluid{
  padding-top: 1.5em;
}

#professionals .professionals-title{
  margin-top: 3em;
}

@media screen and (max-width: 1400px) and (orientation: landscape) {
  #professionals {    
    height: 110vh;
  }
}

@media screen and (max-width: 1400px) and (orientation: landscape) and (orientation: portrait){ 
  #professionals h2 {
    font-size: 2.5em;
  } 
  #professionals .card-img-top{
    width: 10vw;
    height: 10vw; 
  }
  #professionals article h4{
    font-size: 0.8em;
  }
  #professionals article h6{
    font-size: 1em;
  }
  #professionals p{
    font-size: 0.6em;
    line-height: 1.2em;
  }
  
  #professionals .container-fluid{
    padding-top: .1em;
  }
  #professionals .professionals-title{
    margin-top: 2em;
  }
}
@media screen and (max-width: 600px) and (orientation: portrait) {
  #professionals {
    height: 100vh;
  }
  #professionals h2 {
    font-size: 2.3em;
  } 
  #professionals .VueCarousel-wrapper{
    padding-left: 1em;
  }
  #professionals .professionals-title{
    margin-top: 1em;
  }
} 

@media screen and (max-width: 440px) and (orientation: portrait) {
  #professionals .card-img-top{
    width: 30vw;
    height: 30vw; 
  }
}
@media screen and (max-width: 360px) and (orientation: portrait) {
  #professionals article{
    padding: .5em;
  }
  #professionals h2 {
    font-size: 1.8em;
  } 
  #professionals article h4{
    font-size: 0.8em;
  }
}
</style>