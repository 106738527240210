<template>
  <div class="admin-body">
    <div class="header-admin">
      <h1>Resumen de procedimientos</h1>
      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Inventore autem non minima hic laboriosam dicta?</p>
    </div>
    <b-container>
      <b-form @submit.stop.prevent="findProcedures">
        <b-row class="mt-4 justify-content-center">
          <b-col cols="4">
            <input-select
              :placeholder="'Tipo de documento'"
              :options="documents"
              @value="idtipodoc = $event"
            ></input-select>
          </b-col>
          <b-col cols="4 justify-content-center">
            <input-text
              :placeholder="'Número de documento'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="numedoc = $event"
            ></input-text>
          </b-col>
        </b-row>
        <b-row class="mt-3 justify-content-center">
          <b-col>
            <button-submit
              :btnBackgroundColor="'#00a098'"
              :text="'Consultar'"
              :btnWidth="'300px'"
              :btnFontSize="'1.6em'"
            ></button-submit>
          </b-col>
        </b-row>
      </b-form>

      <b-row class="mt-5 justify-content-center row-info">
        <b-col cols="3"><p>{{patientName}}</p></b-col>
        <b-col cols="0"><p>|</p></b-col>
        <b-col cols="2"><p>{{patientDocument}}</p></b-col>
        <b-col cols="0"><p>|</p></b-col>
        <b-col cols="3"><p>{{patientCel}}</p></b-col>
        <b-col cols="0"><p>|</p></b-col>
        <b-col cols="2"><p>{{patientMail}}</p></b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table
            class="mt-3"
            hover
            bordered
            responsive
            sticky-header
            :items="procedures"
            :fields="fields"
            :filter="filter"
          >   
            <template #cell(estado)="row">
              <b-button
                variant="success"
                size="sm"
                @click="info(row.item)"
                class="mr-1">
                Ver más
              </b-button>         
              <b-button
                v-if="row.item.idestagesproc == 1"
                variant="success"
                size="sm"
                @click="changeState(row.item.idgestion_procedimiento, 2)"
                class="mr-1">
                Atendido
              </b-button>
              <b-button
                v-else
                variant="danger"
                size="sm"
                @click="changeState(row.item.idgestion_procedimiento, 1)"
                class="mr-1">
                Pendiente
              </b-button>     
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row class="pt-3 justify-content-center">
        <b-col cols="6">
          <b-alert
            :show="dismissCountDown"
            dismissible
            :variant="typeAlert"
            @dismissed="dismissCountDown = 0"
            @dismiss-count-down="countDownChanged"
          >
            {{ message }} {{ dismissCountDown }}
          </b-alert>
        </b-col>
      </b-row>
    </b-container>
    <b-modal size="xl" ref="procedure-detail-modal" hide-footer headerClass='border-bottom-0' >        
      <detail-procedure :currentDiary="currentDiary" :patient="patient" :procedure="procedure" ></detail-procedure>
    </b-modal>
  </div>
</template>

<script>
import Procedure from "@/services/procedures.service"
import InputText from "@/components/inputs/InputText.vue";
import InputSelect from "@/components/inputs/InputSelect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import DetailProcedure from "./DetailProcedure.vue";
export default {
  data(){
    return{
      //------
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      message: "",
      typeAlert: "success",
      fields: [
        {key:"idprocedi",label:"Procedimientos",
          formatter: (value) => {
            return this.localProcedures.filter(function (el){  
              return el.idprocedimiento == value 
            })[0].nombre_procedimiento
          },
        }, 
        {
          key: "created_at",
          label: "Fecha",
          formatter: (value) => {
            if (value) {
              return value.slice(0, 10);
            }
            return value
          },
        },
        {key:"idresproc",label:"Resultado Procedimiento", 
          formatter: (value) => {
            return this.results.filter(function (el){  
                return el.idresultados_procedimiento == value 
            })[0].nombre_resultados_procedimiento
          },
        },
        { key: "estado", label: "Acciones" },
      ],
      filter: "",
      //patient data
      patient: {},
      patientMail: "Correo",
      patientDocument: "Documento",
      patientCel: "Número celular",
      patientName: "Nombre paciente",

      procedure: {},
      currentDiary: {},
      localProcedures: [],
      procedures: [],
      documents: [],
      payments: [],
      results: [],
      states: []
    }
  },
  components:{
    InputText,
    InputSelect,
    ButtonSubmit,
    DetailProcedure,
  },
  created(){
    this.documents = JSON.parse(localStorage.resources).tipo_documentos
    this.results = JSON.parse(localStorage.resources).resultados_procedimiento
    this.localProcedures = JSON.parse(localStorage.resources).procedimientos
    this.states = JSON.parse(localStorage.resources).estados_gestion_procedimiento
  },
  
  methods: {
    findProcedures() {
      Procedure.list(      
        this.idtipodoc, this.numedoc
      )
      .then((res) => {
        this.patient = res.data.paciente[0]
        this.patientName  = this.patient.pnombre
        this.patientMail = this.patient.email
        this.patientCel = this.patient.celular
        this.patientDocument = this.patient.numedoc
        this.procedures = res.data.gestion_procedimiento
      })
      .catch((err) => console.log(err));
    },
    info(item) {
      this.currentDiary = item
      this.currentDiary.resproc = this.results.filter((el)=>{  
        return el.idresultados_procedimiento == this.currentDiary.idresproc 
      })[0].nombre_resultados_procedimiento
      this.procedure = this.localProcedures.filter((el)=>{
        return el.idprocedimiento == this.currentDiary.idprocedi
      })[0].nombre_procedimiento
      this.$refs['procedure-detail-modal'].show()
    },
    changeState(idProcedure, state){
      Procedure.changeState(idProcedure, state)
      .then((res) => {
        this.message = res.message
        this.showAlert();
        this.findProcedures();
      })
      .catch((err) => console.log(err));
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },

}
</script>

<style>
 
</style>