<template>
  <div class="check-permission">
    <label for="__BVID__89">{{text}}</label>
    <b-form-checkbox
      size="lg"
      v-model="status"
      value="accepted"
      unchecked-value="not_accepted"
    >
    </b-form-checkbox>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: null
    }
  },
  updated() {
    this.$emit("value", this.status);
  },
  props: {
    text: {
      type: String,
      default: "text",
    },
  }
}
</script>

<style>
  .check-permission{
    display: flex;
  }
  .custom-control-label::before{
    content: "";
    width: 1.5em !important;
    height: 1.5em !important;
    top: 0 !important;
    left: 0em !important;
  }
  .custom-control-label::after{
    background-color: #00A098;
    content: "";
    width: 1.5em !important;
    height: 1.5em !important;
    top: 0 !important;
    left: 0em !important;
  }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after{
    background-size: contain;
  }
</style>