import env from '@/environment/env'

const create = async (
    idtipodoc,
    idRole,
    pnombre,
    snombre,
    papellido,
    sapellido,
    numedoc,
    email,
    direccion,
    celular,
    password,
    ) => {
    try{
        let body = {
            'idtipodoc':idtipodoc,
            'primer_nombre':pnombre,
            'segundo_nombre':snombre,
            'primer_apellido':papellido,
            'segundo_apellido':sapellido,
            'numedoc':numedoc,
            'email':email,
            'idrole':idRole,
            'direccion':direccion,
            'celular':celular,
            'password':password,
        }
        const res = await fetch(env.prod+'usuario',{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            },
            body: JSON.stringify(body)
        })
        const data = await res.json();
        return data;
    }catch(error){
        return error
    }
}

const edit = async (user) => {
    try{
        let body = {
            'idtipodoc':user.idtipodoc,
            'primer_nombre':user.primer_nombre,
            'segundo_nombre':user.segundo_nombre,
            'primer_apellido':user.primer_apellido,
            'segundo_apellido':user.segundo_apellido,
            'numedoc':user.numedoc,
            'email':user.email,
            'idrole':user.roles[0].id,
            'direccion':user.direccion,
            'celular':user.celular,
            'password':user.password,
        }
        const res = await fetch(env.prod+'usuario/'+user.idusuario,{
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            },
            body: JSON.stringify(body)
        })
        const data = await res.json();
        return data;
    }catch(error){
        return error
    }
}

const list = async ()=>{
    try {
        const res = await fetch(env.prod+'usuario',{
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            }
        })
        const data = await res.json()
        return data
    } catch (error) {
        return error
    }
}

const listByRole = async (role)=> {
    try {
        let body = {
            'rol': role
        }
        const res = await fetch(env.prod+'usuario/byRole',{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('key')
            },
            body: JSON.stringify(body)
        })
        const data = await res.json()
        return data
    } catch (error) {
        return error   
    }
}

export default{
    create, list, edit, listByRole
}