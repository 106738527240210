<template>
  <div class="admin-body">
    <div class="header-admin">
      <h1>Gestión de Procedimientos</h1>
      <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
    </div>
    <b-container>
      <b-form @submit.stop.prevent="findDiary">
        <b-row class="mt-4 justify-content-center">
          <b-col cols="4">
            <input-select
              :placeholder="'Tipo de documento'"
              :options="documents"
              @value="idtipodoc = $event"
            ></input-select>
          </b-col>
          <b-col cols="4 justify-content-center">
            <input-text
              :placeholder="'Número de documento'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="numedoc = $event"
            ></input-text>
          </b-col>
        </b-row>
        <b-row class="mt-3 justify-content-center">
          <b-col>
            <button-submit
              :btnBackgroundColor="'#00a098'"
              :text="'Consultar'"
              :btnWidth="'300px'"
              :btnFontSize="'1.6em'"
            ></button-submit>
          </b-col>
        </b-row>
      </b-form>

      <b-row class="mt-5 justify-content-center row-info">
        <b-col cols="3"><p>{{patientName}}</p></b-col>
        <b-col cols="0"><p>|</p></b-col>
        <b-col cols="2"><p>{{patientDocument}}</p></b-col>
        <b-col cols="0"><p>|</p></b-col>
        <b-col cols="3"><p>{{patientCel}}</p></b-col>
        <b-col cols="0"><p>|</p></b-col>
        <b-col cols="2"><p>{{patientMail}}</p></b-col>
      </b-row>

      <b-row class="mt-2 justify-content-center">
        <b-col cols="4">
          <input-diaries
            :placeholder="'Procedimiento'"
            :procedures="procedures"
            :options="diaries"
            @value="diary = $event"
            @onDiary="showDiary"
          ></input-diaries>
        </b-col>
      </b-row>

      <b-row class="mt-4 justify-content-center row-info">
        <b-col cols="3"><p>{{procedureDate}}</p></b-col>
        <b-col cols="0"><p>|</p></b-col>
        <b-col cols="3"><p>{{procedureTime}}</p></b-col>
        <b-col cols="0"><p>|</p></b-col>
        <b-col cols="3"><p>{{procedurePlace}}</p></b-col>
      </b-row>

      <b-form @submit.stop.prevent="createProcedure">
        <b-row class="mt-2 justify-content-center">
          <b-col cols="4">
            <input-results
              :placeholder="'Resultado Prcedimiento'"
              :options="results"
              @value="idResult = $event"
            ></input-results>
          </b-col>
          <b-col cols="4 justify-content-center">
            <input-text
              :placeholder="'Lugar Alojamiento'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="housingPlace = $event"
            ></input-text>
          </b-col>
        </b-row>

        <b-row class="mt-2 justify-content-center">
          <b-col cols="8">
            <input-text
              :type="'text'"
              :placeholder="'Observaciones'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="observartions = $event"
            ></input-text>
          </b-col>
        </b-row>

        <b-row class="mt-2 justify-content-center">
          <b-col cols="4">
            <input-text
              :placeholder="'Aerolínea con la que voló'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="airline = $event"
            ></input-text>
          </b-col>
          <b-col cols="4">
            <input-text
              :minimum="6"
              :placeholder="'Destinos visitados'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="visitedLocations = $event"
            ></input-text>
          </b-col>
        </b-row>
        <b-row class="mt-2 justify-content-center">
          <b-col cols="4">
            <input-text
              :minimum="4"
              :placeholder="'Acompañante de viaje'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="travelPartner = $event"
            ></input-text>
          </b-col>
          <b-col cols="4">
            <input-text
              :minimum="9"
              :placeholder="'Número acompañante'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="numberPartner = $event"
            ></input-text>
          </b-col>
        </b-row>
        <b-row class="mt-3 justify-content-center">
          <b-col>
            <button-submit
              :btnBackgroundColor="'#00a098'"
              :text="'Registrar'"
              :btnWidth="'300px'"
              :btnFontSize="'1.6em'"
            ></button-submit>
          </b-col>
        </b-row>
      </b-form>
      <b-row class="pt-3 justify-content-center">
        <b-col cols="6">
          <b-alert
            :show="dismissCountDown"
            dismissible
            :variant="typeAlert"
            @dismissed="dismissCountDown = 0"
            @dismiss-count-down="countDownChanged"
          >
            {{ message }} {{ dismissCountDown }}
          </b-alert>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import Diary from "@/services/diary.service";
import ProcedureService from "@/services/procedures.service";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import InputResults from "@/components/inputs/InputResults";
import InputDiaries from "@/components/inputs/InputDiaries";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";

export default {
  name: "create-patient",
  data() {
    return {
      //------
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      message: "",
      typeAlert: "success",
      //patient data
      idtipodoc: "",
      numedoc: "",
      patientMail: "Correo",
      patientDocument: "Documento",
      patientCel: "Número celular",
      patientName: "Nombre paciente",
      //procedure data
      procedureDate: "Fecha procedimiento",
      procedureTime: "Hora procedimiento",
      procedurePlace: "Lugar procedimiento",
      
      //form data
      diary: {},
      idProcedure:'',
      idResult:'',
      housingPlace:'',
      airline:'',
      visitedLocations:'',
      travelPartner:'',
      numberPartner:'',
      observartions:'',

      diaries: [],
      documents: [],      
      results: [],
      procedures: [],
      ipss: [],

      that: this,
    };
  },
  components: {
    InputText,
    InputSelect,
    InputResults,
    InputDiaries,
    ButtonSubmit,
  },
  methods: {
    createProcedure(){      
      this.procedureDate = moment(this.procedureDate).format("YYYY/MM/DD");
      ProcedureService.create(
        this.patient.idpaciente,
        this.diary.idprocedi,
        this.idResult.idresultados_procedimiento,
        this.procedureDate,
        this.procedureTime,
        this.procedurePlace,
        this.housingPlace,
        this.airline,
        this.visitedLocations,
        this.travelPartner,
        this.numberPartner,
        this.observartions,
      )
      .then(res => {
        this.message = res.message
        this.showAlert();
      })
      .catch(err => console.log(err) )
    },
    findDiary() {
      Diary.find(      
        this.idtipodoc, this.numedoc
      )
      .then((res) => {
        this.patient = res.data.paciente[0]
        this.patientName  = this.patient.pnombre
        this.patientMail = this.patient.email
        this.patientCel = this.patient.celular
        this.patientDocument = this.patient.numedoc
        this.diaries = res.data.agenda
      })
      .catch((err) => console.log(err));
    },
    showDiary() {
      if(this.diary){
        this.procedureDate = this.diary.fechaproc
        this.procedureTime = this.diary.horaproc        
        this.procedurePlace = this.ipss.filter((el) => {          
          return el.idips == this.diary.idips 
        })[0].nombre_ips
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
  created() {
    this.documents = JSON.parse(localStorage.resources).tipo_documentos;
    this.results = JSON.parse(localStorage.resources).resultados_procedimiento    
    this.procedures = JSON.parse(localStorage.resources).procedimientos;
    this.ipss = JSON.parse(localStorage.resources).ips;
  },
};
</script>