<template>
  <div class="admin-body">
    <div class="header-admin">
      <h1>Crear Rol</h1>
      <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
    </div>
    <b-container class="pt-5">
      <b-form @submit.stop.prevent="createRole">
        <b-row class="mt-5 justify-content-center">
          <b-col cols="4 justify-content-center">
            <input-text
              :placeholder="'Nombre Rol'"
              :txtBorder="'none'"
              :txtBackground="'transparent'"
              :txtShadow="'-3px 3px 0px 2px #00a098'"
              @text="name = $event"              
            ></input-text>
          </b-col>
        </b-row>
        <b-row class="mt-5 justify-content-center">
          <b-col>
            <button-submit
              :btnBackgroundColor="'#00a098'"
              :text="'Guardar'"
              :btnWidth="'300px'"
              :btnFontSize="'1.6em'"
            ></button-submit>
          </b-col>
        </b-row>
      </b-form>
      <b-row class="pt-5">
        <b-col>
          <b-alert
            :show="dismissCountDown"
            dismissible
            :variant="typeAlert"
            @dismissed="dismissCountDown = 0"
            @dismiss-count-down="countDownChanged"
          >
            {{message}} {{ dismissCountDown }}
          </b-alert>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Role from "@/services/role.service";
import InputText from "@/components/inputs/InputText";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";

export default {
  data() {
    return {
      name: "",
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      message: '',
      typeAlert: 'success',
    };
  },
  components: {
    InputText,
    ButtonSubmit,
  },
  methods: {
    createRole() {
      Role.create(this.name)
        .then((res) => {
          this.message = this.name+" "+res.message
          this.showAlert();
        })
        .catch((err) => console.log(err));
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },    
  },
};
</script>
<style>
.row-info p {
  color: #00a098;
  font-size: 1.1em;
  font-weight: bold;
}
</style>